import {Tokens, UserInterface} from './auth.model';

export interface AuthState {
    isLoggedIn: boolean;
    currentUser?: UserInterface;
    tokens: Tokens;
    lastRoute?: string;

    requesting: boolean;
    error?: string;
}

export const initialState: AuthState = {
    isLoggedIn: false,
    currentUser: undefined,
    tokens: {},
    requesting: false,
};
