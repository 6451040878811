import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {IsGrantedDirective} from './directives/is-granted.directive';
import {WriteActionComponent} from './components/write-action.component';

@NgModule({
    imports: [CommonModule],
    declarations: [IsGrantedDirective, WriteActionComponent],
    exports: [IsGrantedDirective, WriteActionComponent],
})
export class SecurityModule {}
