import {AfterViewInit, Component, ErrorHandler, HostListener, Inject, NgZone, OnInit} from '@angular/core';
import {ApplicationLoaderService, Loading} from '@application/data';
import {DialogHandle, DialogService} from '@ui/dialog';
import {Observable, map, tap} from 'rxjs';
import {VersionableApiType, convertType} from '@versionable/data';
import {AuthStore} from '@user/auth';
import {BreadcrumbService} from '@ui/breadcrumb';
import {ErrorHandlerService} from '@axiocode/error-handler';
import {Hotkeys} from '@configuration';
import {HotkeysDialogComponent} from '@ui/hotkeys';
import {HotkeysService} from '@ngneat/hotkeys';
import {NavigationPlanService} from '@ui/navigation-plan';
import {NotificationService} from '@ui/notification';
import {PermissionService} from '@user/security';
import {Router} from '@angular/router';
import {ScrollingService} from '@ui/scrolling';
import {SubSink} from 'subsink';
import {TitleService} from '@seo';
import {UserStore} from '@user/data';

import {toSignal} from '@angular/core/rxjs-interop';

type MentionType = VersionableApiType | 'actor' | 'usecase' | 'applicationcomponent' | 'application' | 'glossaryterm';

@Component({
    selector: 'mgcn-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
    isLoggedIn$: Observable<boolean>;
    isLoadingApplication$: Observable<Loading>;
    #dialogHandle?: DialogHandle = undefined;

    private subs = new SubSink();

    currentApplication = toSignal(this.applicationService.currentApplication$);

    constructor(
        private router: Router,
        private authStore: AuthStore,
        private userStore: UserStore,
        private ngZone: NgZone,
        private applicationService: ApplicationLoaderService,
        private notificationService: NotificationService,
        private permissionService: PermissionService,
        private titleService: TitleService,
        private breadcrumbService: BreadcrumbService,
        private navigationService: NavigationPlanService,
        private hotkeys: HotkeysService,
        private dialogService: DialogService,
        private scrollingService: ScrollingService,
        @Inject(ErrorHandler) private errorHandlerService: ErrorHandlerService
    ) {
        this.isLoggedIn$ = this.authStore.selectIsLoggedIn$;
        this.isLoadingApplication$ = this.applicationService.isLoading$;
        this.userStore.initialize();

        // This displays errors, no matter where they happen
        this.errorHandlerService.error$
            .pipe(
                // @todo filtrer et traiter les erreurs selon le type (+ context?) et pas juste print les messages API
                map(error => {
                    if (error.formErrors.length) {
                        error.message = 'ERROR.FORM_ERROR';

                        return {error, translate: true};
                    }

                    return {error, translate: error.translate};
                }),
                tap(({error, translate}) => this.notificationService.notifyError(error.message, undefined, translate))
            )
            .subscribe();
        this.applicationService.error$
            .pipe(tap(error => this.notificationService.notifyError(error.message)))
            .subscribe();

        this.titleService.initialize();
        this.breadcrumbService.initialize();
        this.navigationService.initialize();
    }

    /** @ignore */
    ngOnInit(): void {
        // @ts-ignore
        window['angularManagicianComponentReference'] = {
            component: this,
            zone: this.ngZone,
            openEditionDrawer: (id: string, entity: 'datamodel' | 'relation') => this.openEditionPreview(id, entity),
            openMentionPreviewDrawer: (
                id: string,
                entity: MentionType
            ) => this.openMentionPreview(id, entity),
        };
    }

    ngAfterViewInit(): void {
        this.hotkeys.setSequenceDebounce(400);
        this.subs.sink = this.hotkeys.addShortcut({
            keys: Hotkeys.ESC_DIALOG,
            group: 'KEYMAP.GROUP.GENERAL',
            description: 'KEYMAP.ESC_DIALOG',
            allowIn: ['INPUT', 'SELECT', 'TEXTAREA']
        }).subscribe(() => this.dialogService.close());
        this.hotkeys.registerHelpModal(() => {
            if (this.#dialogHandle) {
                this.#dialogHandle.close();
            }
            this.#dialogHandle = this.dialogService.open(HotkeysDialogComponent);
        });
    }

    @HostListener('document:scroll', ['$event'])
    onScroll(): void {
        const scrollY = window.scrollY;
        this.scrollingService.contentScrollEvent(scrollY);
    }

    private openMentionPreview(
        id: string,
        entity: MentionType
    ): void {
        switch (entity) {
            case 'actor':
            case 'applicationcomponent':
            case 'application':
            case 'glossaryterm':
            case 'usecase':
                this.router.navigate([
                    '',
                    {
                        outlets: {drawer: ['preview', entity, id]},
                    },
                ]);
                break;

            default:
                this.router.navigate([
                    '',
                    {
                        outlets: {drawer: ['preview', convertType(entity), id]},
                    },
                ]);
                break;
        }
    }

    private openEditionPreview(id: string, entity: 'datamodel' | 'relation'): void {
        const application = this.currentApplication();
        if (application && !this.permissionService.isGranted({
            target: application,
            permission: 'write',
            type: 'application' // @TODO : changer sur la branche 2.x-SI pour l'appliquer sur le SI
        })) {
            return;
        }

        if ('datamodel' === entity) {
            this.router.navigate([
                '',
                {
                    outlets: {drawer: ['preview', entity, 'quickedit', id]},
                },
            ]);
        } else if ('relation' === entity) {
            this.router.navigate([
                '',
                {
                    outlets: {drawer: ['preview', entity, 'relationedit', id]},
                },
            ]);
        }
    }
}
