import {Application, ApplicationLoaderService, ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, distinctUntilChanged, map, tap} from 'rxjs';
import {DataModelStore} from '@data-model/data';
import {HttpErrorResponse} from '@angular/common/http';
import {SubSink} from 'subsink';
import {firstOrNull} from '@utils';

import {GlossaryTerm} from '../models/glossary-term.model';
import {GlossaryTermProvider} from './glossary-term.provider';
import {GlossaryTermState} from '../models/glossary-term-state.interface';

export function initializeApplicationLoader(service: ApplicationLoaderService, provider: GlossaryTermProvider, store: GlossaryTermStore) {
    return () => service.registerLoader((app: Application) => provider.findAll$().pipe(tap(data => store.upsertMany(data))));
}

@Injectable({providedIn: 'root'})
export class GlossaryTermStore extends EntityStore<GlossaryTerm, GlossaryTermState> implements OnDestroy {
    #created = new Subject<EntitiesChange<GlossaryTerm>>();
    get created(): Observable<EntitiesChange<GlossaryTerm>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<GlossaryTerm>>();
    get updated(): Observable<EntitiesChange<GlossaryTerm>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<GlossaryTerm>>();
    get deleted(): Observable<EntitiesChange<GlossaryTerm>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    /** @ignore */
    private subs = new SubSink();

    // Callbacks
    override afterCreate = (data: GlossaryTerm) => this.applicationStore.refreshCurrentApplication$.pipe(map(() => data));

    public override onSuccess(change: EntitiesChange<GlossaryTerm>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<GlossaryTerm, GlossaryTermState> {
        return {
            storeName: 'GlossaryTermStore',
            initialState: {ids: [], entities: {}},
            selectId: glossaryTerm => glossaryTerm.id,
        };
    }

    constructor(
        provider: GlossaryTermProvider,
        private applicationStore: ApplicationStore,
        private dataModelStore: DataModelStore,
    ) {
        super(provider);

        this.subs.sink = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();

        this.subs.sink = this.dataModelStore.created.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity) {
                // If a term has been generated from the model, we need to refresh the glossary terms store.
                this.reset(); // Ensure we don't have HTTP cache
                this.findAll();
            }
        });

        this.subs.sink = this.dataModelStore.updated.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity && entity.glossaryTerm) {
                this.findOne(entity.glossaryTerm.id);
            }
        });

        this.subs.sink = this.dataModelStore.deleted.subscribe(change => {
            const entity = firstOrNull(change.entities);
            if (entity && entity.glossaryTerm) {
                this.findOne(entity.glossaryTerm.id);
            }
        });
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subs.unsubscribe();
    }
}
