export function removeAccents(text: string): string {
    text = text.replace(/[ÀÁÂÃÄÅ]/g,'A');
    text = text.replace(/[àáâãäå]/g,'a');
    text = text.replace(/[ÈÉÊË]/g,'E');
    text = text.replace(/[èéêë]/g,'e');
    text = text.replace(/[ÙÚÛÜ]/g,'U');
    text = text.replace(/[ùúûü]/g,'u');
    text = text.replace(/[ÌÍÎÏ]/g,'I');
    text = text.replace(/[ìíîï]/g,'i');
    text = text.replace(/[ÒÓÔÖ]/g,'O');
    text = text.replace(/[òóôö]/g,'o');
    text = text.replace(/[ŶŸ]/g,'Y');
    text = text.replace(/[ŷÿ]/g,'y');
    text = text.replace(/[Ç]/g,'C');
    text = text.replace(/[ç]/g,'c');

    return text;
}

export function removeRegexSymbols(text: string): string {
    text = text.replace(/[()]/g, '.');
    text = text.replace(/[{}\\[\]]/g, '');
    text = text.replace(/\*\*/g, '*');

    return text;
}

export function wrapAt(text: string, before: string, after: string, start: number, end: number): string {
    return text.substring(0, start) + before + text.substring(start, end) + after + text.substring(end);
}
