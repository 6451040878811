<div class="container-login">
    <mat-card appearance="outlined" class="text-center" class="login">
        <img
            mat-card-image
            class="light"
            alt="logo managician"
            src="../../assets/auth/images/managician-logo.svg"
            width="400"
        />
        <img
            mat-card-image
            class="dark"
            alt="logo managician"
            src="../../assets/auth/images/managician-logo-white.svg"
            width="400"
        />
        <mat-card-title>
            {{ "LOGIN.FORGOTTEN_PASSWORD_TITLE" | translate }}
        </mat-card-title>

        <auth-password-forgotten-form
            [error]="(error$ | async) ?? undefined"
            [loading]="(requesting$ | async) ?? false"
            [loginUrl]="config.loginUrl"
            (submitted)="passwordForgotten($event)"
        ></auth-password-forgotten-form>
    </mat-card>
</div>
