import {ApiConfig, ApiToken} from '@token';
import {Application, ApplicationStore} from '@application/data';
import {ConvertSnakeToCamelCase, EntityProvider, IdType} from '@axiocode/entity';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription, distinctUntilChanged, map, tap} from 'rxjs';
import {GenerateRequirementModel} from '@data-model/data';
import {HttpContext} from '@angular/common/http';
import {withCache} from '@ngneat/cashew';

import {Feature} from '../models/feature.model';

export interface FeatureSvg {
    featureSvg: string;
    featureSvgWithoutCrud: string
}

@Injectable({providedIn: 'root'})
export class FeatureProvider extends EntityProvider<Feature> implements OnDestroy {
    /** @ignore */
    private application: Application | undefined = undefined;
    /** @ignore */
    private subscription: Subscription;

    override findAll$(): Observable<Feature[]> {
        if (undefined === this.application) {
            throw new Error('Current application is not defined.');
        }

        return this._findAll$(`${this.config.apiBaseUrl}/api/application/${this.application.id}/features`);
    }

    override findOne$(id: IdType): Observable<Feature> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/feature/${id}`);
    }

    override create$(data: Partial<Feature>): Observable<Feature> {
        return this._create$(`${this.config.apiBaseUrl}/api/feature`, data);
    }

    override update$(data: Partial<Feature>, method: 'patch' | 'put'): Observable<Feature> {
        return this._update$(`${this.config.apiBaseUrl}/api/feature/${data.id}`, data, method);
    }

    override delete$(data: Feature): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/feature/${data.id}`);
    }

    findFeatureUML$(featureId: IdType): Observable<FeatureSvg> {
        if (undefined === this.application) {
            throw new Error('Current application is not defined.');
        }

        return this.http.get<FeatureSvg>(`${this.config.apiBaseUrl}/api/application/${this.application.id}/umldiagram/features/?feature=${featureId}`, {
            context: withCache({
                cache: true,
                bucket: this.cacheBucket,
                ttl: this.ttl,
            }).set(ConvertSnakeToCamelCase, true),
            withCredentials: false
        }).pipe(
            map(url => ({
                featureSvg: url.featureSvg,
                featureSvgWithoutCrud: url.featureSvgWithoutCrud
            })
            )
        );
    }

    createFeatureCrudFromDataModel(data: GenerateRequirementModel): Observable<Feature> {
        if (undefined === this.application) {
            throw new Error('Current application is not defined.');
        }

        return this.http.post<Feature>(`${this.config.apiBaseUrl}/api/usecase/generate/${data.dataModel?.id}`, data.useCaseCrud, {
            context: new HttpContext().set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion)
        });
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
        applicationStore: ApplicationStore,
    ) {
        super();

        this.ttl = this.config.ttl;

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current application
            tap(() => this.resetCache()),
            tap(app => this.application = app),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
