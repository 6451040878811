import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DialogComponent} from '@ui/dialog';

@Component({
    selector: 'hotkeys-dialog',
    template: `
        <div class="dialog-inline-title">
            <h2 mat-dialog-title>{{ 'KEYMAP.DIALOG_TITLE' | translate }}</h2>
            <button class="button-link-icon" mat-icon-button mat-dialog-close>
                X
            </button>
        </div>
        <mat-dialog-content class="modal">
            <table class="table table-full hotkeys-table" *ngFor="let hotkeyGroup of hotkeys">
                <thead *ngIf="hotkeyGroup.group">
                <tr>
                    <th class="text-left"><h3>{{ hotkeyGroup.group | translate}}</h3></th>
                </tr>
                </thead>
                <tbody>
                <tr class="hotkeys-line" *ngFor="let hotkey of hotkeyGroup.hotkeys">
                    <td>{{ hotkey.description | translate }}</td>
                    <td class="text-right">
                        <kbd [innerHTML]="hotkey.keys | hotkeysShortcut"></kbd>
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="hotkeys-about">Managician {{ release }}</div>
        </mat-dialog-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class HotkeysDialogComponent extends DialogComponent {
    hotkeys = this.hotkeysService.getShortcuts();
    release = '2.0'; // @todo
}
