import {GlossaryTerm} from '@glossary-term/data';

import {Mention} from '../../models/mention.interface';

export type SimpleTerm = Pick<GlossaryTerm, 'id' | 'word' | 'description'>;

export const mapTerms = (terms: GlossaryTerm[]): Mention[] => {
    let allTerms: SimpleTerm[] = [];
    terms.forEach(term => {
        insertNewTerm(term, allTerms);
        term.plurals?.forEach(plural =>
            insertNewTerm({id: term.id, word: plural.word, description: term.description}, allTerms)
        );

        term.synonyms?.forEach(synonym =>
            insertNewTerm({id: term.id, word: synonym.word, description: term.description}, allTerms)
        );
    });

    return allTerms.map(mapTerm);
};

const mapTerm = (entity: SimpleTerm): Mention => ({
    id: entity.word ?? '',
    text: entity.word ?? '',
    type: 'glossaryterm',
    entity: 'GlossaryTerm',
    entityId: entity.id,
    data: entity,
    description: entity.description ?? ''
});

const insertNewTerm = (term: SimpleTerm, allTerms: SimpleTerm[]): void => {
    let upperCaseTerm: SimpleTerm = {
        id: term.id,
        word: (term.word?.charAt(0).toUpperCase() as string) + term.word?.slice(1),
        description: term.description
    };
    let lowerCaseTerm: SimpleTerm = {
        id: term.id,
        word: (term.word?.charAt(0).toLowerCase() as string) + term.word?.slice(1),
        description: term.description
    };
    allTerms.push(upperCaseTerm, lowerCaseTerm);
};
