import * as Sentry from '@sentry/angular-ivy';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {Inject, Injectable} from '@angular/core';
import {Observable, filter, first, map, switchMap, tap} from 'rxjs';
import {SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {CookieService} from 'ngx-cookie-service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {getRouterSelectors} from '@ngrx/router-store';

import {AuthModuleConfig, AuthModuleToken, UserProviderToken} from '../token';
import {AuthState, initialState} from '../models/auth-state.interface';
import {Tokens, UserInterface} from '../models/auth.model';
import {AuthService} from './auth.service';
import {Identifiers} from '../models/identifiers.model';
import {UserProviderInterface} from './user-provider.interface';
import {linkToGlobalState} from './debug/auth-debug.store';

@Injectable({providedIn: 'root'})
export class AuthStore extends ComponentStore<AuthState> {
    // Selectors
    readonly selectIsLoggedIn$: Observable<boolean> = this.select(state => state.isLoggedIn);
    readonly selectCurrentUser$: Observable<UserInterface | undefined> = this.select(state => state.currentUser);
    readonly selectTokens$: Observable<Tokens> = this.select(state => state.tokens);
    readonly selectError$: Observable<string | undefined> = this.select(state => state.error);
    readonly selectRequesting$: Observable<boolean> = this.select(state => state.requesting);
    readonly selectLastRoute$: Observable<string | undefined> = this.select(state => state.lastRoute);

    // Effects
    readonly login = this.effect((identifiers$: Observable<Identifiers>) => {
        return identifiers$.pipe(
            tap(() => this.onLoginStart()),
            switchMap(identifiers =>
                this.authService.authenticate(identifiers).pipe(
                    tapResponse(
                        tokens => {
                            if (tokens.refresh_token) {
                                this.cookieService.set(
                                    'refresh_token',
                                    tokens.refresh_token,
                                    30,
                                    '/',
                                    undefined,
                                    undefined,
                                    'Strict'
                                );
                            }
                            this.onAuthenticated(tokens);
                            this.fetchUser();
                        },
                        (error: HttpErrorResponse) => this.onError('authstore.login.error')
                    )
                )
            )
        );
    });

    readonly loginWithGoogle = this.effect((user$: Observable<SocialUser>) => {
        return user$.pipe(
            tap(() => this.onLoginStart()),
            switchMap(identifiers =>
                this.authService.authenticateWithGoogle(identifiers).pipe(
                    tapResponse(
                        tokens => {
                            if (tokens.refresh_token) {
                                this.cookieService.set(
                                    'refresh_token',
                                    tokens.refresh_token,
                                    30,
                                    '/',
                                    undefined,
                                    undefined,
                                    'Strict'
                                );
                            }
                            this.onAuthenticated(tokens);
                            this.fetchUser();
                        },
                        (error: HttpErrorResponse) => this.onError('authstore.loginWithGoogle.error')
                    )
                )
            )
        );
    });

    readonly logout = this.effect(effect$ => effect$.pipe(
        // On logout we need to delete auth cookies
        tap(() => this.cookieService.delete('refresh_token', '/')),
        tap(() => this.cookieService.delete('jwt_hp', '/')),
        tap(() => this.socialAuthService.signOut()),
        tap(() => this.onLoggedOut()),
        tap(() => this.router.navigateByUrl(this.config.loginUrl)),
    ));

    readonly fetchUser = this.effect(fetch$ => fetch$.pipe(
        switchMap(() =>
            this.userProvider.fetchCurrentUserInformation$().pipe(
                tapResponse(
                    user => {
                        Sentry.setUser({username: user.username});
                        this.onLoggedIn(user);
                    },
                    (error: HttpErrorResponse) => this.onError('authstore.fetchUser.error')
                ),
                switchMap(() => this.selectLastRoute$.pipe(
                    first(), // Otherwise will be called each time the route changes
                    map(route => route ?? this.config.redirectAfterLogin),
                    tap(route => this.router.navigateByUrl(route))
                ))
            )
        ),
    ));

    // Reducers
    readonly onError = this.updater((state, error: string) => ({
        ...state,
        error,
        requesting: false,
    }));
    readonly onLoginStart = this.updater(state => ({
        ...state,
        error: undefined,
        requesting: true,
    }));
    readonly onLoggedIn = this.updater((state, user: UserInterface) => ({
        ...state,
        isLoggedIn: true,
        currentUser: user,
        requesting: false,
    }));

    readonly onLoggedOut = this.updater(state => ({
        ...initialState,
        lastRoute: state.lastRoute,
    }));
    readonly onAuthenticated = this.updater((state, tokens: Tokens) => ({
        ...state,
        tokens,
    }));
    readonly onNavigation = this.updater((state, url: string) => ({
        ...state,
        lastRoute: url,
    }));

    constructor(
        @Inject(AuthModuleToken) private readonly config: AuthModuleConfig,
        @Inject(UserProviderToken) private readonly userProvider: UserProviderInterface,
        private readonly authService: AuthService,
        private readonly socialAuthService: SocialAuthService,
        private cookieService: CookieService,
        private router: Router,
        private store: Store
    ) {
        super(initialState);

        // Store the last visited URL in the state so that we can redirect to it after a successful login
        this.store
            .select(getRouterSelectors().selectUrl)
            .pipe(
                filter(url => undefined !== url),
                filter(url => !url.startsWith('/auth')),
                filter(url => !url.startsWith('/login')),
                filter(url => url !== '/'),
                filter(url => url.length > 0),
                tap(url => this.onNavigation(url)),
            )
            .subscribe();

        // NgRx chrome dev tools
        linkToGlobalState(this.state$, 'AuthStore', this.store);
    }
}
