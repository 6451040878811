import {NgModule, Optional, SkipSelf} from '@angular/core';
import {ApplicationLoaderService} from '@application/data';
import {AxiocodeEntityModule} from '@axiocode/entity';
import {HttpClientModule} from '@angular/common/http';

import {FunctionalRequirementStore, initializeApplicationLoader} from './services/functional-requirement.store';
import {FunctionalRequirementProvider} from './services/functional-requirement.provider';

@NgModule({
    imports: [
        HttpClientModule,
        AxiocodeEntityModule,
    ],
})
export class FunctionalRequirementDataModule {
    constructor(
        loader: ApplicationLoaderService, provider: FunctionalRequirementProvider, store: FunctionalRequirementStore,
        @Optional() @SkipSelf() parentModule?: FunctionalRequirementDataModule
    ) {
        if (parentModule) {
            throw new Error('FunctionalRequirementDataModule is already loaded. Import it in the AppModule only.');
        }

        initializeApplicationLoader(loader, provider, store)();
    }
}
