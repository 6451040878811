import {IdType, extract} from '@axiocode/entity';
import {Application} from '@application/data';
import {Requirement} from '@versionable/data';

export interface FunctionalRequirement extends Requirement {
    description?: string;
    formattedDescription?: string;
    type: string;
    emailSubject?: string | null;
    emailBody: string;
    emailRecipient?: string | null;
    emailReplyTo?: string | null;
    application?: Application;
    nbOpenDiscussions: number;
}

export const functionalRequirementTrackBy = (index: number, item: FunctionalRequirement): IdType => item.id;

export const formatAPI = extract<FunctionalRequirement>({
    id: true,
    name: true,
    description: true,
    code: true,
    formattedDescription: false,
    type: true,
    emailSubject: true,
    emailBody: true,
    emailRecipient: true,
    emailReplyTo: true,
    application: extract({
        id: true,
    }),
    typeClass: false,
    codeAndPrefix: false,
    features: false,
    entityType: false,
    updatedBy: false,
    updatedAt: false,
    nbOpenDiscussions: false
});
