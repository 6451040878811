import {Application, ApplicationLoaderService, ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription, distinctUntilChanged, map, tap} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {sortByCode} from '@utils';

import {NonFunctionalRequirement} from '../models/non-functional-requirement.model';
import {NonFunctionalRequirementProvider} from './non-functional-requirement.provider';
import {NonFunctionalRequirementState} from '../models/non-functional-requirement-state.interface';

export function initializeApplicationLoader(service: ApplicationLoaderService, provider: NonFunctionalRequirementProvider, store: NonFunctionalRequirementStore) {
    return () => service.registerLoader((app: Application) => provider.findAll$().pipe(tap(data => store.upsertMany(data))));
}

@Injectable({providedIn: 'root'})
export class NonFunctionalRequirementStore extends EntityStore<NonFunctionalRequirement, NonFunctionalRequirementState> implements OnDestroy {
    #created = new Subject<EntitiesChange<NonFunctionalRequirement>>();
    get created(): Observable<EntitiesChange<NonFunctionalRequirement>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<NonFunctionalRequirement>>();
    get updated(): Observable<EntitiesChange<NonFunctionalRequirement>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<NonFunctionalRequirement>>();
    get deleted(): Observable<EntitiesChange<NonFunctionalRequirement>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    /** @ignore */
    private subscription: Subscription;

    // Callbacks
    override afterCreate = (data: NonFunctionalRequirement) => this.applicationStore.refreshCurrentApplication$.pipe(map(() => data));

    public override onSuccess(change: EntitiesChange<NonFunctionalRequirement>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<NonFunctionalRequirement, NonFunctionalRequirementState> {
        return {
            storeName: 'NonFunctionalRequirementStore',
            initialState: {ids: [], entities: {}},
            selectId: nonFunctionalRequirement => nonFunctionalRequirement.id,
            sort: sortByCode,
        };
    }

    constructor(provider: NonFunctionalRequirementProvider, private applicationStore: ApplicationStore) {
        super(provider);

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscription.unsubscribe();
    }
}
