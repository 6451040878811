import {Configuration, ConfigurationStore} from '@configuration';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, filter, map, take, tap} from 'rxjs';
import {SubSink} from 'subsink';
import {sprintf} from 'sprintf-js';

import {Versionable, VersionableType} from '../models/versionable.model';
import {exhaustiveMatchGuard} from '@utils';

@Injectable({providedIn: 'root'})
export class CodeFormatterService implements OnDestroy {
    #configurations$: Observable<Configuration | undefined>;
    #configurations?: Configuration;
    #subs = new SubSink();

    constructor(private configurationStore: ConfigurationStore) {
        this.configurationStore.load();
        this.#configurations$ = this.configurationStore.selectConfigurations$;
        this.#subs.sink = this.configurationStore.selectConfigurations$.pipe(
            tap(configurations => this.#configurations = configurations)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.#subs.unsubscribe();
    }

    format(versionable: Pick<Versionable, 'id' | 'code'>, type: VersionableType): Observable<string>;
    /**
     * You should avoid using the synchronous format() method if you can and use the one that returns an Observable instead.
     */
    // eslint-disable-next-line no-dupe-class-members
    format(versionable: Pick<Versionable, 'id' | 'code'>, type: VersionableType, synchronous: boolean): string;
    // eslint-disable-next-line no-dupe-class-members
    format(versionable: Pick<Versionable, 'id' | 'code'>, type: VersionableType, synchronous?: boolean): Observable<string> | string {
        if (undefined !== synchronous) {
            return this.doFormat(this.#configurations, versionable, type);
        }

        return this.#configurations$.pipe(
            filter((config): config is Configuration => undefined !== config),
            take(1),
            map(configurations => {
                return this.doFormat(configurations, versionable, type);
            })
        );
    }

    private doFormat(configurations: Configuration | undefined, versionable: Pick<Versionable, 'id' | 'code'>, type: VersionableType) {
        let format = '%(prefix)s%(code)03d'; // default format
        let prefix = ''; // default prefix
        let code = versionable.code ?? '';

        switch (type) {
            case 'datamodel':
                prefix = configurations?.dataModelCodePrefix ?? prefix;
                format = configurations?.dataModelCodeFormat ?? format;
                break;

            case 'feature':
                prefix = configurations?.featureCodePrefix ?? prefix;
                format = configurations?.featureCodeFormat ?? format;
                break;

            case 'functionalrequirement':
                prefix = configurations?.functionalRequirementCodePrefix ?? prefix;
                format = configurations?.functionalRequirementCodeFormat ?? format;
                break;

            case 'nonfunctionalrequirement':
                prefix = configurations?.nonFunctionalRequirementCodePrefix ?? prefix;
                format = configurations?.nonFunctionalRequirementCodeFormat ?? format;
                break;

            case 'page':
                prefix = configurations?.nonFunctionalRequirementPageCodePrefix ?? prefix;
                format = configurations?.nonFunctionalRequirementPageCodeFormat ?? format;
                break;

            case 'usecase':
                prefix = configurations?.useCaseCodePrefix ?? prefix;
                format = configurations?.useCaseCodeFormat ?? format;
                break;

            case 'form':
                prefix = configurations?.formCodePrefix ?? prefix;
                format = configurations?.formCodeFormat ?? format;
                break;

            case 'table':
                prefix = configurations?.tableCodePrefix ?? prefix;
                format = configurations?.tableCodeFormat ?? format;
                break;

            case 'actor':
                prefix = configurations?.actorCodePrefix ?? prefix;
                format = configurations?.actorCodeFormat ?? format;
                break;

            case 'glossaryterm':
                prefix = configurations?.glossaryTermCodePrefix ?? prefix;
                format = configurations?.glossaryTermCodeFormat ?? format;
                break;

            case 'appcomponent':
                break;

            default:
                return exhaustiveMatchGuard(type);
        }

        return sprintf(format, {code, prefix});
    }
}
