import {Application, ApplicationLoaderService, ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription, distinctUntilChanged, map, tap} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {sortByCode} from '@utils';

import {Table} from '../models/table.model';
import {TableProvider} from './table.provider';
import {TableState} from '../models/table-state.interface';

export function initializeApplicationLoader(service: ApplicationLoaderService, provider: TableProvider, store: TableStore) {
    return () => service.registerLoader((app: Application) => provider.findAll$().pipe(tap(data => store.upsertMany(data))));
}

@Injectable({providedIn: 'root'})
export class TableStore extends EntityStore<Table, TableState> implements OnDestroy {
    #created = new Subject<EntitiesChange<Table>>();
    get created(): Observable<EntitiesChange<Table>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Table>>();
    get updated(): Observable<EntitiesChange<Table>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Table>>();
    get deleted(): Observable<EntitiesChange<Table>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    /** @ignore */
    private subscription: Subscription;

    // Callbacks
    override afterCreate = (data: Table) => this.applicationStore.refreshCurrentApplication$.pipe(map(() => data));

    // Selectors
    readonly selectAllTablesByFeature$ = (feature: {id: IdType}) => this.select(
        this.selectAll$,
        tables => tables.filter(table => table.features.find(f => f.id === feature.id))
    );

    public override onSuccess(change: EntitiesChange<Table>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Table, TableState> {
        return {
            storeName: 'TableStore',
            initialState: {ids: [], entities: {}},
            selectId: table => table.id,
            sort: sortByCode,
        };
    }

    constructor(provider: TableProvider, private applicationStore: ApplicationStore) {
        super(provider);

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscription.unsubscribe();
    }
}
