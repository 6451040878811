import {Injectable, inject} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DrawerService {

    #router = inject(Router);

    closeDrawer(): Promise<boolean> {
        return this.#router.navigate(['', {outlets: {drawer: null}}]);
    }

    back(): void {
        window.history.back();
    }
}
