import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {NotificationService} from './services/notification.service';

@NgModule({
    imports: [MatSnackBarModule, TranslateModule.forChild()],
    providers: [NotificationService]
})
export class NotificationModule {}
