import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AuthModuleConfig, AuthModuleToken} from '../token';

@Injectable()
export class PasswordResetService {
    // eslint-disable-next-line no-empty-function
    constructor(@Inject(AuthModuleToken) private readonly config: AuthModuleConfig, private http: HttpClient) {}

    /**
     * Sends a password forgotten request to the API for the given email address. Always returns a 200 response, the
     * user has to check his emails.
     */
    public passwordForgotten(email: string): Observable<void> {
        return this.http.post<void>(`${this.config.apiBaseUrl}/api/forgotten-password`, {email});
    }

    /**
     * Sends a password reset request to the API with the given new password for the given token. The token is generated
     * by the API and is provided to the User in the forgotten password email.
     */
    public resetPassword(token: string, password: string): Observable<void> {
        return this.http.post<void>(`${this.config.apiBaseUrl}/api/reset-password/${token}`, {password});
    }
}
