import {Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, inject} from '@angular/core';
import {Hotkey as HotkeyStruct, HotkeysService} from '@ngneat/hotkeys';
import {SubSink} from 'subsink';

@Directive({selector: '[hotkey]'})
export class HotkeyDirective implements OnChanges, OnDestroy {
    @Input() hotkey!: string;
    @Input() hotkeySequence = false;
    @Input() hotkeyGroup?: string;
    @Input() hotkeyDescription?: string;
    @Input() hotkeyAllowedInForms = false;
    @Input() hotkeyElement?: ElementRef = undefined;

    #subs = new SubSink();
    #cache?: HotkeyStruct;
    service = inject(HotkeysService);
    element = inject(ElementRef);

    ngOnChanges(changes: SimpleChanges): void {
        this.removeHotkey();
        if (!this.hotkey) {
            return;
        }

        const hotkey: HotkeyStruct = {
            keys: this.hotkey,
            group: this.hotkeyGroup,
            description: this.hotkeyDescription
        };

        if (this.hotkeyAllowedInForms) {
            hotkey.allowIn = ['INPUT', 'SELECT', 'TEXTAREA'];
        }

        if (this.hotkeyElement) {
            hotkey.element = this.hotkeyElement.nativeElement;
        }

        this.hotkeySequence ? this.setSequenceHotkey(hotkey) : this.setHotkey(hotkey);
        this.#cache = hotkey;
    }

    ngOnDestroy(): void {
        this.removeHotkey();
    }

    private setHotkey(hotkey: HotkeyStruct): void {
        this.#subs.sink = this.service.addShortcut(hotkey).subscribe(
            () => this.element.nativeElement.click()
        );
    }

    private setSequenceHotkey(hotkey: HotkeyStruct) {
        this.#subs.sink = this.service.addSequenceShortcut(hotkey).subscribe(
            () => this.element.nativeElement.click()
        );
    }

    private removeHotkey(): void {
        this.#subs.unsubscribe();
        if (this.#cache) {
            this.service.removeShortcuts(this.#cache.keys);
        }
    }
}