import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {QuickMenuItem} from '../models/quick-menu-item.interface';

@Injectable({providedIn: 'root'})
export class QuickMenuLoaderService {
    #items = new BehaviorSubject<QuickMenuItem[]>([]);
    get items$(): Observable<QuickMenuItem[]> {
        return this.#items.asObservable();
    }

    public addItem(item: QuickMenuItem): void {
        const found = this.#items.getValue().find(i => i.label === item.label);
        if (undefined === found) {
            this.#items.next([
                ...this.#items.getValue(),
                item
            ]);
        }
    }
}