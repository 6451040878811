import {Application, ApplicationLoaderService, ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription, distinctUntilChanged, tap} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

import {AppComponent} from '../models/app-component.model';
import {AppComponentProvider} from './app-component.provider';
import {AppComponentState} from '../models/app-component-state.interface';

export function initializeApplicationLoader(service: ApplicationLoaderService, provider: AppComponentProvider, store: AppComponentStore) {
    return () => service.registerLoader((app: Application) => provider.findAll$().pipe(tap(data => store.upsertMany(data))));
}

@Injectable({providedIn: 'root'})
export class AppComponentStore extends EntityStore<AppComponent, AppComponentState> implements OnDestroy {
    #created = new Subject<EntitiesChange<AppComponent>>();
    get created(): Observable<EntitiesChange<AppComponent>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<AppComponent>>();
    get updated(): Observable<EntitiesChange<AppComponent>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<AppComponent>>();
    get deleted(): Observable<EntitiesChange<AppComponent>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    /** @ignore */
    private subscription: Subscription;

    public override onSuccess(change: EntitiesChange<AppComponent>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<AppComponent, AppComponentState> {
        return {
            storeName: 'AppComponentStore',
            initialState: {ids: [], entities: {}},
            selectId: appComponent => appComponent.id,
        };
    }

    constructor(provider: AppComponentProvider, applicationStore: ApplicationStore) {
        super(provider);

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscription.unsubscribe();
    }
}
