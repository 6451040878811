export function sortNumeric(a: number, b: number, direction: string) {
    a = a ? a : 0;
    b = b ? b : 0;

    return (a <= b ? -1 : 1) * ('desc' === direction ? -1 : 1);
}

export function sortObjectName<T extends {name: string | undefined}>(a: T, b: T, direction: string) {
    let A = a?.name ? a.name : '';
    let B = b?.name ? b.name : '';

    return (A <= B ? -1 : 1) * ('desc' === direction ? -1 : 1);
}

export function sortByCode<T extends {code?: number}>(a: T, b: T, direction: 'asc' | 'desc' = 'asc') {
    let A = a?.code ? a.code : Number.MAX_VALUE;
    let B = b?.code ? b.code : Number.MAX_VALUE;

    return (A <= B ? -1 : 1) * ('desc' === direction ? -1 : 1);
}