import {ActivatedRouteSnapshot, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {filter, map} from 'rxjs';

/**
 * Filter operator to filter only NavigationEnd events.
 */
export function filterNavigationEndGuard() {
    return filter((event): event is NavigationEnd => event instanceof NavigationEnd);
}

/**
 * Filter operator to filter out drawer navigation events.
 */
export function excludeDrawerFromNavigationEvent<T extends RouterEvent>() {
    return filter((event: T) => null === event.url.match(/\(drawer:(.*)\)/));
}

/**
 * Map operator to map a router event to the last child route.
 */
export function mapToRouteLastChild<T extends RouterEvent>(router: Router) {
    return map((event: T) => {
        let route = router.routerState.snapshot.root;
        while (route.firstChild) {
            route = route.firstChild;
        }

        return route;
    });
}

/**
 * Map operator to map a route snapshot to the configured title of that route if a title is found.
 */
export function mapToRouteTitle() {
    return map((snapshot: ActivatedRouteSnapshot) => {
        if (snapshot.data['title']) {
            return snapshot.data['title'] as string;
        }

        return undefined;
    });
}
