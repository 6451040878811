import {Application, ApplicationLoaderService, ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, Subscription, distinctUntilChanged, map, tap} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {sortByCode} from '@utils';

import {Form} from '../models/form.model';
import {FormProvider} from './form.provider';
import {FormState} from '../models/form-state.interface';

export function initializeApplicationLoader(service: ApplicationLoaderService, provider: FormProvider, store: FormStore) {
    return () => service.registerLoader((app: Application) => provider.findAll$().pipe(tap(data => store.upsertMany(data))));
}

@Injectable({providedIn: 'root'})
export class FormStore extends EntityStore<Form, FormState> implements OnDestroy {
    #created = new Subject<EntitiesChange<Form>>();
    get created(): Observable<EntitiesChange<Form>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Form>>();
    get updated(): Observable<EntitiesChange<Form>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Form>>();
    get deleted(): Observable<EntitiesChange<Form>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    /** @ignore */
    private subscription: Subscription;

    // Callbacks
    override afterCreate = (data: Form) => this.applicationStore.refreshCurrentApplication$.pipe(map(() => data));

    // Selectors
    readonly selectAllFormsByFeature$ = (feature: {id: IdType}) => this.select(
        this.selectAll$,
        forms => forms.filter(form => form.features.find(f => f.id === feature.id))
    );

    public override onSuccess(change: EntitiesChange<Form>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Form, FormState> {
        return {
            storeName: 'FormStore',
            initialState: {ids: [], entities: {}},
            selectId: form => form.id,
            sort: sortByCode,
        };
    }

    constructor(provider: FormProvider, private applicationStore: ApplicationStore) {
        super(provider);

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscription.unsubscribe();
    }
}
