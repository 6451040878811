import {ApiConfig, ApiToken} from '@token';
import {Application, ApplicationStore} from '@application/data';
import {ConvertSnakeToCamelCase, EntityProvider, IdType} from '@axiocode/entity';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription, distinctUntilChanged, map, tap} from 'rxjs';
import {HttpContext} from '@angular/common/http';

import {Actor} from '../models/actor.model';

@Injectable({providedIn: 'root'})
export class ActorProvider extends EntityProvider<Actor> implements OnDestroy {
    /** @ignore */
    private application: Application | undefined = undefined;
    /** @ignore */
    private subscription: Subscription;

    override findAll$(): Observable<Actor[]> {
        if (undefined === this.application) {
            throw new Error('Current application is not defined.');
        }

        return this._findAll$(`${this.config.apiBaseUrl}/api/application/${this.application.id}/actors`);
    }

    override findOne$(id: IdType): Observable<Actor> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/actor/${id}`);
    }

    override create$(data: Partial<Actor>): Observable<Actor> {
        return this._create$(`${this.config.apiBaseUrl}/api/actor`, data);
    }

    override update$(data: Partial<Actor>, method: 'patch' | 'put'): Observable<Actor> {
        return this._update$(`${this.config.apiBaseUrl}/api/actor/${data.id}`, data, method);
    }

    override delete$(data: Actor): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/actor/${data.id}`);
    }

    findActorsUML$(): Observable<string> {
        if (undefined === this.application) {
            throw new Error('Current application is not defined.');
        }

        return this.http.get<{useCaseSvg: string}>(`${this.config.apiBaseUrl}/api/application/${this.application.id}/umldiagram/actors`, {
            context: (new HttpContext()).set(ConvertSnakeToCamelCase, true),
            withCredentials: false
        }).pipe(
            map(url => url.useCaseSvg)
        );
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
        applicationStore: ApplicationStore,
    ) {
        super();

        this.ttl = this.config.ttl;

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current application
            tap(() => this.resetCache()),
            tap(app => this.application = app),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
