import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {Observable, filter, tap} from 'rxjs';
import {NotificationService} from '@ui/notification';
import {SubSink} from 'subsink';

import {AuthModuleConfig, AuthModuleToken} from '../../token';
import {PasswordForgottenStore} from '../../services/password-forgotten.store';

@Component({
    selector: 'auth-password-forgotten',
    templateUrl: './password-forgotten.component.html',
    styleUrls: ['./password-forgotten.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PasswordForgottenStore],
})
export class PasswordForgottenComponent implements OnDestroy {
    requesting$: Observable<boolean> = this.passwordForgottenStore.selectRequesting$;
    error$: Observable<string | undefined> = this.passwordForgottenStore.selectError$.pipe(
        filter(error => undefined !== error),
        tap(() => this.notificationService.notifyError('ERROR.FORGOTTEN_PASSWORD'))
    );
    success$: Observable<boolean> = this.passwordForgottenStore.selectRequestSuccess$.pipe(
        filter(success => true === success),
        tap(() => this.notificationService.notifySuccess('LOGIN.MESSAGE.EMAIL_RESET_PASSWORD_SEND'))
    );

    private subs = new SubSink();

    constructor(
        @Inject(AuthModuleToken) public readonly config: AuthModuleConfig,
        private readonly passwordForgottenStore: PasswordForgottenStore,
        private readonly notificationService: NotificationService,
    ) {
        this.subs.sink = this.success$.subscribe();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public passwordForgotten(email: string): void {
        this.passwordForgottenStore.sendPasswordForgottenRequest(email);
    }
}
