import {Actor, ActorStore} from '@actor/data';
import {CodeFormatterService, Versionable, VersionableApiType, VersionableType} from '@versionable/data';
import {Observable, combineLatest, distinct, map} from 'rxjs';
import {AppComponentStore} from '@app-component/data';
import {ApplicationStore} from '@application/data';
import {DataModelStore} from '@data-model/data';
import {FeatureStore} from '@feature/data';
import {FormStore} from '@form/data';
import {FunctionalRequirementStore} from '@functional-requirement/data';
import {GlossaryTermStore} from '@glossary-term/data';
import {IdType} from '@axiocode/entity';
import {Injectable} from '@angular/core';
import {NonFunctionalRequirementStore} from '@non-functional-requirement/data';
import {PageStore} from '@page/data';
import {TableStore} from '@table/data';
import {UseCaseStore} from '@use-case/data';

import {Mention} from '../models/mention.interface';
import {mapTerms} from './utils/term-mention';

const mapActor = (entity: Actor, codeFormatterService: CodeFormatterService): Mention => ({
    id: `@${entity.name}`,
    text: entity.name ?? '',
    type: 'actor',
    entity: 'actor',
    entityId: entity.id,
    code: codeFormatterService.format(entity, 'actor', true)
});
const mapRequirement = (entity: Versionable, type: VersionableApiType, codeType: VersionableType, codeFormatterService: CodeFormatterService): Mention => ({
    id: `#${entity.name}`,
    text: entity.name ?? '',
    type: 'requirement',
    entity: type,
    entityId: entity.id,
    code: codeFormatterService.format(entity, codeType, true)
});

const mapSystem = (entity: {id: IdType, name?: string}, type: 'application' | 'applicationcomponent'): Mention => ({
    id: `@${entity.name}`,
    text: entity.name ?? '',
    type: 'system',
    entity: type,
    entityId: entity.id
});

@Injectable({providedIn: 'root'})
export class MentionStore {
    readonly agglomerateMention$ = combineLatest([
        this.actorStore.selectAll$.pipe(map(entities => entities.map(entity => mapActor(entity, this.codeFormatterService)))),
        this.dataModelStore.selectAll$.pipe(map(entities => entities.map(entity => mapRequirement(entity, 'DataModel', 'datamodel', this.codeFormatterService)))),
        this.featureStore.selectAll$.pipe(map(entities => entities.map(entity => mapRequirement(entity, 'Feature', 'feature', this.codeFormatterService)))),
        this.formStore.selectAll$.pipe(map(entities => entities.map(entity => mapRequirement(entity, 'Form', 'form', this.codeFormatterService)))),
        this.funcReqStore.selectAll$.pipe(map(entities => entities.map(entity => mapRequirement(entity, 'FunctionalRequirement', 'functionalrequirement', this.codeFormatterService)))),
        this.nonFuncReqStore.selectAll$.pipe(map(entities => entities.map(entity => mapRequirement(entity, 'NonFunctionalRequirement', 'nonfunctionalrequirement', this.codeFormatterService)))),
        this.pageStore.selectAll$.pipe(map(entities => entities.map(entity => mapRequirement(entity, 'NonFunctionalRequirementPage', 'page', this.codeFormatterService)))),
        this.useCaseStore.selectAll$.pipe(map(entities => entities.map(entity => mapRequirement(entity, 'UseCase', 'usecase', this.codeFormatterService)))),
        this.tableStore.selectAll$.pipe(map(entities => entities.map(entity => mapRequirement(entity, 'DataTable', 'table', this.codeFormatterService)))),
        this.termStore.selectAll$.pipe(map(mapTerms)),
        this.applicationStore.selectSelectedEntity$.pipe(map(current => (current ? [mapSystem(current, 'application')] : []))),
        this.appComponentsStore.selectAll$.pipe(map(entities => entities.map(entity => mapSystem(entity, 'applicationcomponent')))),
    ]);

    readonly selectMentions$: Observable<Mention[]> = this.agglomerateMention$.pipe(
        distinct(),
        map(mentions => mentions.reduce((accumulator, value) => [
            ...accumulator,
            ...value
        ], [])),
    );

    constructor(
        private actorStore: ActorStore,
        private appComponentsStore: AppComponentStore,
        private dataModelStore: DataModelStore,
        private tableStore: TableStore,
        private featureStore: FeatureStore,
        private formStore: FormStore,
        private funcReqStore: FunctionalRequirementStore,
        private termStore: GlossaryTermStore,
        private nonFuncReqStore: NonFunctionalRequirementStore,
        private pageStore: PageStore,
        private useCaseStore: UseCaseStore,
        private applicationStore: ApplicationStore,
        private codeFormatterService: CodeFormatterService,
        // eslint-disable-next-line no-empty-function
    ) {}
}
