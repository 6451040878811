import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {AppRoutes} from '@routing';
import {inject} from '@angular/core';
import {tap} from 'rxjs';

import {FunctionalRequirement} from '../models/functional-requirement.model';
import {FunctionalRequirementProvider} from '../services/functional-requirement.provider';
import {FunctionalRequirementStore} from '../services/functional-requirement.store';

/**
 * This resolver fetches the functionalRequirement from the path parameter "functionalRequirementId" and sets the functionalRequirement as the current one.
 */
export const functionalRequirementResolver: ResolveFn<FunctionalRequirement | undefined | null> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const routes = inject(AppRoutes);
    const store = inject(FunctionalRequirementStore);
    const provider = inject(FunctionalRequirementProvider);
    const id = route.paramMap.get('functionalRequirementId');
    const applicationId = route.paramMap.get('applicationId');

    if (null === id) {
        return null;
    }

    return provider.findOne$(Number(id)).pipe(
        tap(functionalRequirement => {
            // Check if the URL applications matches the actual entity's application
            if (null !== applicationId && Number(functionalRequirement.application?.id) !== Number(applicationId)) {
                // if not, redirect to the correct application
                if (functionalRequirement.application) {
                    routes.application.in(functionalRequirement.application?.id).functionalRequirement.details(functionalRequirement.id).navigate();
                } else {
                    routes.error404.navigate();
                }
            }
        }),
        tap(functionalRequirement => {
            store.upsertOne(functionalRequirement);
            store.setSelected(functionalRequirement);
        }),
    );
};
