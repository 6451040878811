import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthModuleConfig, AuthModuleToken} from '../token';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    constructor(
        @Inject(AuthModuleToken) private readonly config: AuthModuleConfig,
        // eslint-disable-next-line no-empty-function
    ) {
    }
    /** @ignore */
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // withCredentials tells HttpClient to send the cookies with the request
        if (request.url.startsWith(this.config.apiBaseUrl)) {
            request = request.clone({headers: request.headers.set('Accept-Language', window.localStorage.getItem('lang') || 'fr')});
        }

        return next.handle(request);
    }
}
