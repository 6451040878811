import {CacheBucket, HttpCacheManager, withCache} from '@ngneat/cashew';
import {HttpClient, HttpContext} from '@angular/common/http';
import {Observable} from 'rxjs';
import {inject} from '@angular/core';

import {ConvertSnakeToCamelCase} from '../interceptors/snake-to-camel.interceptor';
import {IdType} from '../interfaces/entity-state.interface';

export abstract class EntityProvider<T> {
    /** @ignore */
    protected cacheBucket = new CacheBucket();

    /** Set to false to disable HTTP cache. */
    protected enableCache = true;

    /** The cache duration in milliseconds. Override it to change cache duration. */
    protected ttl = 10000;

    /** 
     * When working with state management like ngrx, there is no need to save the data both in the
     * cache and in the store because the store is the single source of truth. In such a case, the
     * only thing we want is an indication of whether the data is in the cache.
     */
    protected cacheMode: 'stateManagement' | 'cache' = 'stateManagement';

    /** Set to false to disable the conversion from snake_case to camelCase. */
    protected enableSnakeCaseToCamelCaseConversion = true;

    /** @ignore */
    protected http: HttpClient = inject(HttpClient);

    /** @ignore */
    protected manager: HttpCacheManager = inject(HttpCacheManager);

    abstract findAll$(): Observable<T[]>;
    protected _findAll$(url: string): Observable<T[]> {
        return this.http.get<T[]>(url, {
            context: withCache({
                cache: this.enableCache,
                bucket: this.cacheBucket,
                mode: this.cacheMode,
                ttl: this.ttl,

            }).set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion),
        });
    }

    abstract findOne$(id: IdType): Observable<T>;
    protected _findOne$(url: string): Observable<T> {
        return this.http.get<T>(url, {
            context: withCache({
                cache: this.enableCache,
                bucket: this.cacheBucket,
                ttl: this.ttl,
            }).set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion),
        });
    }

    abstract create$(data: Partial<T>): Observable<T>;
    protected _create$(url: string, data: Partial<T>): Observable<T> {
        return this.http.post<T>(url, data, {
            context: new HttpContext().set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion)
        });
    }

    abstract update$(data: Partial<T>, method: 'patch' | 'put'): Observable<T>;
    protected _update$(url: string, data: Partial<T>, method: 'patch' | 'put' = 'patch'): Observable<T> {
        if ('patch' === method) {
            return this.http.patch<T>(url, data, {
                context: new HttpContext().set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion)
            });
        }

        return this.http.put<T>(url, data, {
            context: new HttpContext().set(ConvertSnakeToCamelCase, this.enableSnakeCaseToCamelCaseConversion)
        });
    }

    abstract delete$(data: T): Observable<void>;
    protected _delete$(url: string): Observable<void> {
        return this.http.delete<void>(url);
    }

    public resetCache(): void {
        this.manager.delete(this.cacheBucket);
    }
}
