import {Application, ApplicationLoaderService, ApplicationStore} from '@application/data';
import {EMPTY, Observable, Subject, Subscription, catchError, concatMap, distinctUntilChanged, map, tap} from 'rxjs';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {Injectable, OnDestroy, inject} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {sortByCode} from '@utils';

import {DataModel} from '../models/data-model.model';
import {DataModelProvider} from './data-model.provider';
import {DataModelState} from '../models/data-model-state.interface';

export function initializeApplicationLoader(service: ApplicationLoaderService, provider: DataModelProvider, store: DataModelStore) {
    return () => service.registerLoader((app: Application) => provider.findAll$().pipe(tap(data => store.upsertMany(data))));
}

@Injectable({providedIn: 'root'})
export class DataModelStore extends EntityStore<DataModel, DataModelState> implements OnDestroy {
    #created = new Subject<EntitiesChange<DataModel>>();
    get created(): Observable<EntitiesChange<DataModel>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<DataModel>>();
    get updated(): Observable<EntitiesChange<DataModel>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<DataModel>>();
    get deleted(): Observable<EntitiesChange<DataModel>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    #provider = inject(DataModelProvider);

    // Selectors
    readonly selectPlantUmlAllDataModelsContent$ = this.select(state => state.plantUmlAllDataModelsContent);
    readonly selectAvailableFunctionalDomains$ = this.select(
        this.selectAll$,
        dataModels => Array.from(new Set(dataModels.flatMap(dataModel => dataModel.functionalDomains))).sort()
    );

    // Effects
    readonly loadPlantUmlDatamodelsContent = this.effect((trigger$: Observable<{dataModel?: DataModel, domains?: string[]}>) => trigger$.pipe(
        concatMap(data => this.#provider.findAllClassDiagramContent$(data.dataModel, data.domains).pipe(
            tap(svg => this.setPlantUmlAllDataModelsContent(svg)),
            catchError(() => EMPTY) // ignore errors
        ))
    ));

    // Reducers
    readonly setPlantUmlAllDataModelsContent = this.updater((state, plantUmlAllDataModelsContent: string) => ({
        ...state,
        plantUmlAllDataModelsContent,
    }));

    /** @ignore */
    private subscription: Subscription;

    // Callbacks
    override afterCreate = (data: DataModel) => this.applicationStore.refreshCurrentApplication$.pipe(map(() => data));

    public override onSuccess(change: EntitiesChange<DataModel>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<DataModel, DataModelState> {
        return {
            storeName: 'DataModelStore',
            initialState: {ids: [], entities: {}, plantUmlAllDataModelsContent: undefined},
            selectId: dataModel => dataModel.id,
            sort: sortByCode,
        };
    }

    constructor(provider: DataModelProvider, private applicationStore: ApplicationStore) {
        super(provider);

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscription.unsubscribe();
    }
}
