import {Application, ApplicationLoaderService, ApplicationStore} from '@application/data';
import {
    EMPTY,
    Observable,
    Subject,
    Subscription,
    catchError,
    distinctUntilChanged,
    exhaustMap,
    map,
    switchMap,
    tap
} from 'rxjs';
import {EntitiesChange, EntityAdapter, EntityStore} from '@axiocode/entity';
import {Injectable, OnDestroy} from '@angular/core';
import {GenerateRequirementModel} from '@data-model/data';
import {HttpErrorResponse} from '@angular/common/http';
import {sortByCode} from '@utils';

import {FeatureProvider, FeatureSvg} from './feature.provider';
import {Feature} from '../models/feature.model';
import {FeatureState} from '../models/feature-state.interface';

export function initializeApplicationLoader(service: ApplicationLoaderService, provider: FeatureProvider, store: FeatureStore) {
    return () => service.registerLoader((app: Application) => provider.findAll$().pipe(tap(data => store.upsertMany(data))));
}

@Injectable({providedIn: 'root'})
export class FeatureStore extends EntityStore<Feature, FeatureState, FeatureProvider> implements OnDestroy {
    #created = new Subject<EntitiesChange<Feature>>();
    get created(): Observable<EntitiesChange<Feature>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Feature>>();
    get updated(): Observable<EntitiesChange<Feature>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Feature>>();
    get deleted(): Observable<EntitiesChange<Feature>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    #generatedCrud = new Subject<Feature>();
    get generatedCrud(): Observable<Feature> {
        return this.#generatedCrud.asObservable();
    }

    // Selectors
    readonly selectPlantUmlContent$ = this.select(state => state.plantUmlContent);
    readonly selectPlantUmlWithoutCrudContent$ = this.select(state => state.plantUmlContentWithoutCrud);

    // Effects
    readonly loadPlantUmlContent = this.effect((trigger$: Observable<Feature>) => trigger$.pipe(
        exhaustMap(feature => this.provider.findFeatureUML$(feature.id).pipe(
            tap(svg => this.setPlantUmlContents(svg)),
            catchError(() => EMPTY) // ignore errors
        ))
    ));

    readonly generateCrud = this.effect((trigger$: Observable<GenerateRequirementModel>) => trigger$.pipe(
        switchMap(data => this.provider.createFeatureCrudFromDataModel(data).pipe(
            tap(feature => this.#generatedCrud.next(feature)),
            catchError(() => EMPTY) // ignore errors
        ))
    ));

    // Reducers
    readonly setPlantUmlContents = this.updater((state, featureSvg: FeatureSvg) => ({
        ...state,
        plantUmlContent: featureSvg.featureSvg,
        plantUmlContentWithoutCrud: featureSvg.featureSvgWithoutCrud
    }));

    /** @ignore */
    private subscription: Subscription;

    // Callbacks
    override afterCreate = (data: Feature) => this.applicationStore.refreshCurrentApplication$.pipe(map(() => data));

    public override onSuccess(change: EntitiesChange<Feature>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Feature, FeatureState> {
        return {
            storeName: 'FeatureStore',
            initialState: {ids: [], entities: {}, plantUmlContent: undefined, plantUmlContentWithoutCrud: undefined},
            selectId: feature => feature.id,
            sort: sortByCode,
        };
    }

    constructor(provider: FeatureProvider, private applicationStore: ApplicationStore) {
        super(provider);

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscription.unsubscribe();
    }

    resetCache(): void {
        this.provider.resetCache();
    }
}
