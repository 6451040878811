import {HttpErrorResponse} from '@angular/common/http';

/**
 * This class contains useful information about an error that occurred in one of the S&M applications.
 */
export class AxioError {
    /**
     * Stack trace for the error.
     */
    public stack;

    /**
     * If there are form errors, they are in this array.
     */
    public formErrors: AxioFormError[] = [];

    /** @ignore */
    constructor(
        /** The error description */
        public message: string,
        /** The context of the error */
        public context?: string,
        /** Does the message need to be translated? */
        public translate = false
    ) {
        this.stack = new Error().stack;
    }

    public static fromHttpError(error: HttpErrorResponse, context?: string): AxioError {
        return new AxioError(error.message, context ?? (error.url as string));
    }
}

export interface AxioFormError {
    field?: string;
    message?: string;
}
