/** @ignore */
export class FormFieldConstraintParametersConfiguration {
    name?: string = undefined;
    display?: boolean = undefined;
    required?: boolean = undefined;
}

/** @ignore */
export class FormFieldConstraintConfiguration {
    name?: string = undefined;
    parameters: FormFieldConstraintParametersConfiguration[] = [];
}

/**
 * Configuration object containing the configurations keys.
 */
export class Configuration {
    actorCodePrefix?: string = undefined;
    actorCodeFormat?: string = undefined;
    featureCodePrefix?: string = undefined;
    featureCodeFormat?: string = undefined;
    functionalRequirementCodePrefix?: string = undefined;
    functionalRequirementCodeFormat?: string = undefined;
    functionalRequirementTypes: string[] = [];
    functionalRequirementTypeConfiguration?: any = undefined; // "any": arbitrary json string that has been JSON.parsed
    nonFunctionalRequirementCodePrefix?: string = undefined;
    nonFunctionalRequirementCodeFormat?: string = undefined;
    nonFunctionalRequirementPageCodePrefix?: string = undefined;
    nonFunctionalRequirementPageCodeFormat?: string = undefined;
    useCaseCodePrefix?: string = undefined;
    useCaseCodeFormat?: string = undefined;
    dataModelCodePrefix?: string = undefined;
    dataModelCodeFormat?: string = undefined;
    dataModelMatchingFormFieldTypes: string[] = [];
    formCodePrefix?: string = undefined;
    formCodeFormat?: string = undefined;
    tableCodePrefix?: string = undefined;
    tableCodeFormat?: string = undefined;
    glossaryTermCodePrefix?: string = undefined;
    glossaryTermCodeFormat?: string = undefined;
    appComponentTypes: string[] = [];
    dataModelTypes: string[] = [];
    dataModelAttributeTypes: string[] = [];
    dataTableColumnTypes: string[] = [];
    formFieldTypes: string[] = [];
    formFieldConstraints: FormFieldConstraintConfiguration[] = [];
    formFieldTypeChoicesRequired: string[] = [];
    pageActionTypes: string[] = [];
}

/** @ignore */
export interface ApiConfiguration {
    configurations: Configuration;
}

/** @ignore */
function parseJSONConfiguration(value: string): unknown {
    try {
        const result = JSON.parse(value);

        return result;
    } catch (e) {
        return value;
    }
}

/**
 * Some of the API fields do not match directly configuration attributes. We also need to deserialize some JSON values.
 */
export function mapConfigurations(fromApi: any): Configuration {
    return {
        ...fromApi,
        appComponentTypes: parseJSONConfiguration(fromApi.appComponentTypes),
        dataModelAttributeTypes: parseJSONConfiguration(fromApi.dataModelAttributeTypes),
        dataModelMatchingDataTableColumnTypes: parseJSONConfiguration(fromApi.dataModelMatchingDataTableColumnTypes),
        dataModelMatchingFormFieldTypes: parseJSONConfiguration(fromApi.dataModelMatchingFormFieldTypes),
        dataModelRelationTypes: parseJSONConfiguration(fromApi.dataModelRelationTypes),
        dataModelTypes: parseJSONConfiguration(fromApi.dataModelTypes),
        dataTableColumnTypes: parseJSONConfiguration(fromApi.dataTableColumnTypes),
        formFieldConstraints: parseJSONConfiguration(fromApi.formFieldConstraints),
        formFieldTypeChoicesRequired: parseJSONConfiguration(fromApi.formFieldTypeChoicesRequired),
        formFieldTypes: parseJSONConfiguration(fromApi.formFieldTypes),
        functionalRequirementTypes: parseJSONConfiguration(fromApi.functionalRequirementTypes),
        pageActionTypes: parseJSONConfiguration(fromApi.pageActionTypes),
    };
}
