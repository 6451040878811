import {ChangeDetectionStrategy, Component, Renderer2, inject} from '@angular/core';

import {DrawerService} from '../../services/drawer.service';

@Component({
    selector: 'drawer-drawer',
    template: `
    <div [ngClass]="{'drawer-preview':show, 'disable-drawer-preview':!show}">
        <router-outlet name="drawer" (activate)="activate()" (deactivate)="deactivate()"></router-outlet>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent {
    show = false;

    #drawerService = inject(DrawerService);
    #renderer = inject(Renderer2);

    close(): void {
        this.#drawerService.closeDrawer();
    }

    back(): void {
        this.#drawerService.back();
    }

    activate(): void {
        this.show = true;
        this.#renderer.addClass(document.body, 'drawer-opened');
    }

    deactivate(): void {
        this.show = false;
        this.#renderer.removeClass(document.body, 'drawer-opened');
    }
}
