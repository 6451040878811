export interface UserInterface {
    username: string;
}

/**
 * List of User possible roles.
 */
export enum Role {
    SuperAdmin = 'ROLE_SUPERADMIN',
}

export interface Tokens {
    token?: string;
    refresh_token?: string;
}

/**
 * Payload of the JWT token.
 */
export interface JwtUserPayload {
    iat: number;
    exp: number;
    roles: Array<Role>;
    username: string;
}
