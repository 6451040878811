import {Store, createAction, createReducer, on, props} from '@ngrx/store';
import {Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

export const updateState = createAction(
    '[Entity Store] Update Action',
    props<{componentName: string, state: object}>()
);

export const entityStateReducer = createReducer(
    {},
    on(updateState, (previousState, {componentName, state}) => {
        return ({
            ...previousState,
            [componentName]: {...state}
        });
    })
);

export const linkToGlobalState = (state$: Observable<object>, componentName: string, globalStore: Store) => {
    state$.pipe(
        distinctUntilChanged((prev, next) => prev === next),
    ).subscribe(state => globalStore.dispatch(updateState({componentName, state})));
};