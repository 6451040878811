/** @ignore */
export type extractFn<Extracted, Base extends Extracted> = (value: Base) => Extracted;

/**
 * Reduce an object of type T to a subset of its properties,
 * determined by the argument "properties".
 * @example
 * interface Actor {
 *  id: number;
 *  name: string;
 *  application: { id: number; name: string };
 * }
 *
 * const actor: Actor = {
 *  id: 32,
 *  name: 'Actor test',
 *  application: {
 *      id: 3,
 *      name: 'App test',
 *  },
 * };
 *
 * const extractId: extractFn<{ id: number }, { id: number; name: string }> = extract<{id: number;}>({ id: true });
 * const extractActorApi = extract({
 *  id: true,
 *  name: true,
 *  application: extractId,
 * });
 *
 * function updateActor(actor: Partial<Actor>): void {
 *  // ...
 * }
 * updateActor(extractActorApi(actor));
 */
export function extract<T>(
    properties: Record<keyof T, boolean | extractFn<any, any>>
) {
    return function <TActual extends T>(value: TActual) {
        let result = {} as T;
        for (const property of Object.keys(properties) as Array<keyof T>) {
            const type = properties[property];
            if (true === type) {
                result[property] = value[property];
            } else if ('function' === typeof type && value[property]) {
                result[property] = type(value[property]);
            }
        }

        return result;
    };
}
