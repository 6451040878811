import {MatSnackBar, MatSnackBarDismiss} from '@angular/material/snack-bar';
import {Router, UrlTree} from '@angular/router';
import {firstValueFrom, take} from 'rxjs';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class NotificationService {
    readonly redirectAfterDismiss = (url: string | UrlTree) => (() => {
        this.redirect(url);
    });

    readonly redirectAfterConfirm = (url: string | UrlTree) => ((dismiss: MatSnackBarDismiss) => {
        if (dismiss.dismissedByAction) {
            this.redirect(url);
        }
    });

    constructor(
        private snackBar: MatSnackBar,
        private translator: TranslateService,
        private router: Router,
        // eslint-disable-next-line no-empty-function
    ) {}

    public async notifySuccess(message: string, duration?: number, translate = true): Promise<MatSnackBarDismiss> {
        return this.notify('success', message, duration ?? 5000, translate);
    }

    public async notifyError(message: string, duration?: number, translate = true): Promise<MatSnackBarDismiss> {
        return this.notify('error', message, duration ?? 15000, translate);
    }

    public async notify(type: 'success' | 'error' | 'info', message: string, duration?: number, translate = true): Promise<MatSnackBarDismiss> {
        let translation = message;
        if (translate) {
            translation = await firstValueFrom(this.translator.get(message).pipe(take(1)));
        }

        return firstValueFrom(this.snackBar.open(translation, 'Ok', {
            duration: duration,
            panelClass: [type],
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
        }).afterDismissed());
    }

    private redirect(url: string | UrlTree): void {
        this.router.navigateByUrl(url);
    }
}
