import {Injectable, inject} from '@angular/core';
import {map, of} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ComponentStore} from '@ngrx/component-store';
import {VersionableState} from '@versionable/data';

import {FunctionalRequirement} from '../models/functional-requirement.model';
import {FunctionalRequirementStore} from './functional-requirement.store';

export interface FunctionalRequirementLocalState {
    currentFunctionalRequirement?: FunctionalRequirement;
    versionableState: VersionableState;
    force: boolean;
    formSaveState: boolean;
}

const defaultState: FunctionalRequirementLocalState = {
    versionableState: 'view',
    force: false, // force edition mode (from drawer)
    currentFunctionalRequirement: undefined,
    formSaveState: false,
};

@Injectable()
export class FunctionalRequirementLocalStore extends ComponentStore<FunctionalRequirementLocalState> {
    // Synchronous operations. Avoid using them if you can.
    get currentFunctionalRequirement(): FunctionalRequirement | undefined {
        return this.get().currentFunctionalRequirement;
    }

    private readonly route = inject(ActivatedRoute);
    private readonly store = inject(FunctionalRequirementStore);

    // Selectors
    private readonly selectCurrent$ = this.select(state => state.currentFunctionalRequirement);
    readonly selectPreview$ = this.route.parent ? this.route.parent.data.pipe(map(data => data['preview'] ?? false)) : of(false);
    readonly selectCurrentFunctionalRequirement$ = this.select(
        this.store.selectAll$,
        this.selectCurrent$,
        // we map the "currentFunctionalRequirement" from the actual FunctionalRequirementStore so that we have the latest available data
        (functionalRequirements, current) => functionalRequirements.find(functionalRequirement => functionalRequirement.id === current?.id)
    );
    readonly selectVersionableState$ = this.select(state => state.versionableState);
    readonly selectSaveState$ = this.select(state => state.formSaveState);

    // Updaters
    readonly setCurrentFunctionalRequirement = this.updater((state, functionalRequirement: FunctionalRequirement) => ({
        ...state,
        currentFunctionalRequirement: functionalRequirement,
    }));
    readonly changeVersionableState = this.updater((state, vState: VersionableState) => ({
        ...state,
        versionableState: vState
    }));
    readonly changeSaveState = this.updater((state, sState: boolean) => ({
        ...state,
        formSaveState: sState
    }));
    readonly setForce = this.updater((state, force: boolean) => ({
        ...state,
        force,
    }));

    constructor() {
        super(defaultState);
    }
}
