import {ComponentStore} from '@ngrx/component-store';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {inject} from '@angular/core';

import {EntityState, IdType} from '../interfaces/entity-state.interface';
import {provideAddMany, provideAddOne, provideRemoveAll, provideRemoveMany, provideRemoveOne, provideSetMany, provideSetSelected, provideUpdateMany, provideUpdateOne, provideUpsertMany, provideUpsertOne} from './store/store-reducers';
import {provideCreate, provideDelete, provideFindAll, provideFindOne, provideUpdate} from './store/store-effects';
import {EntitiesChange} from '../interfaces/entities-change.interface';
import {EntityAdapter} from '../interfaces/entity-adapter.interface';
import {EntityProvider} from './entity-provider.service';
import {EntityStoreInterface} from '../interfaces/entity-store.interface';
import {linkToGlobalState} from './debug/dev-tools.store';

export abstract class EntityStore<T extends object, S extends EntityState<T>, P extends EntityProvider<T> = EntityProvider<T>> extends ComponentStore<S>
    implements EntityStoreInterface<T> {
    protected abstract getEntityAdapter(): EntityAdapter<T, S>;
    protected adapter: EntityAdapter<T, S>;

    // Selectors
    readonly selectIds$ = this.select(state => state.ids);
    /** Returns the entities in no particular order */
    readonly selectEntities$ = this.select(state => state.entities);
    /** Returns ordered entities */
    readonly selectAll$ = this.select(
        this.selectEntities$,
        this.selectIds$,
        (dictionnary, ids) => ids.map(id => dictionnary[id]),
        // prevents selectAll$ to be triggered twice: once with selectEntities$ and once with selectIds$. Also prevents undefined values.
        {debounce: true}
    );
    readonly selectSelectedId$ = this.select(state => state.selectedId);
    readonly selectSelectedEntity$ = this.select(
        this.selectEntities$,
        this.selectSelectedId$,
        (entities, id) => id ? entities[id] : undefined
    );

    // Effects
    readonly findAll = this.effect<void>(trigger$ => provideFindAll(this.provider, this, trigger$));
    readonly findOne = this.effect<IdType>(trigger$ => provideFindOne(this.provider, this, trigger$));
    readonly create = this.effect<Partial<T>>(trigger$ => provideCreate(this.provider, this, trigger$));
    readonly update = this.effect<{data: Partial<T>, method?: 'patch' | 'put'}>(trigger$ => provideUpdate(this.provider, this, trigger$));
    readonly delete = this.effect<T>(trigger$ => provideDelete(this.provider, this, trigger$));

    // Reducers
    readonly setMany = this.updater((state, entities: T[]) => provideSetMany(state, this.adapter, entities));
    readonly addMany = this.updater((state, entities: T[]) => provideAddMany(state, this.adapter, entities));
    readonly addOne = this.updater((state, entity: T) => provideAddOne(state, this.adapter, entity));
    readonly updateMany = this.updater((state, entities: T[]) => provideUpdateMany(state, this.adapter, entities));
    readonly updateOne = this.updater((state, entity: T) => provideUpdateOne(state, this.adapter, entity));
    readonly upsertMany = this.updater((state, entities: T[]) => provideUpsertMany(state, this.adapter, entities));
    readonly upsertOne = this.updater((state, entity: T) => provideUpsertOne(state, this.adapter, entity));
    readonly removeMany = this.updater((state, entities: T[]) => provideRemoveMany(state, this.adapter, entities));
    readonly removeOne = this.updater((state, entity: T) => provideRemoveOne(state, this.adapter, entity));
    readonly removeAll = this.updater(state => provideRemoveAll(state, this.adapter));
    readonly setSelected = this.updater((state, entity: T | undefined) => provideSetSelected(state, this.adapter, entity));

    afterCreate: ((data: T) => Observable<T>) | undefined = undefined;

    constructor(protected readonly provider: P) {
        const store = inject(Store);
        super();
        this.adapter = this.getEntityAdapter();

        // Initial state
        this.reset();

        // NgRx chrome dev tools
        linkToGlobalState(this.state$, this.adapter.storeName, store);
    }

    reset(): void {
        this.provider.resetCache();
        this.setState(this.adapter.initialState);
    }

    /**
     * This method is called if a HTTP error occurs. It does nothing but you can override it
     * in you own stores to provide application specific error handling.
     */
    // eslint-disable-next-line no-empty-function
    public onError(error: HttpErrorResponse): void {}

    /**
     * This method is called if a HTTP request succeeds. It does nothing but you can override it
     * in you own stores to provide application specific effects when an entity is added, updated or deleted.
     */
    // eslint-disable-next-line no-empty-function
    public onSuccess(change: EntitiesChange<T>): void {}
}
