import {Injectable} from '@angular/core';
import {RouteBuilder} from '@axiocode/routing';

import {ApplicationRoutes} from './routes/application-routes.service';
import {AuthRoutes} from './routes/auth-routes.service';
import {OrganizationRoutes} from './routes/organization-routes.service';
import {UserRoutes} from './routes/user-routes.service';

@Injectable({providedIn: 'any'})
export class AppRoutes extends RouteBuilder {
    auth = this.childRoutes('auth', AuthRoutes);
    application = this.childRoutes('application', ApplicationRoutes);
    organization = this.childRoutes('organization', OrganizationRoutes);
    user = this.childRoutes('user', UserRoutes);

    error404 = this.url('not-found');

    login = (returnUrl?: string) => this.urlFromCommands(['login'], {
        ...returnUrl ? {queryParams: {returnUrl}} : {}
    });
}
