import {IdType} from '@axiocode/entity';
import {Injectable} from '@angular/core';
import {RouteBuilder} from '@axiocode/routing';

import {ActorRoutes} from './actor-routes.service';
import {AppComponentRoutes} from './app-component-routes.service';
import {DataModelRoutes} from './data-model-routes.service';
import {DiscussionRoutes} from './discussion-routes.service';
import {FeatureRoutes} from './feature-routes.service';
import {FormRoutes} from './form-routes.service';
import {FunctionalRequirementRoutes} from './functional-requirement-routes.service';
import {GlossaryTermRoutes} from './glossary-term-routes.service';
import {NonFunctionalRequirementRoutes} from './non-functional-requirement-routes.service';
import {PageRoutes} from './page-routes.service';
import {TableRoutes} from './table-routes.service';
import {UseCaseRoutes} from './use-case-routes.service';

@Injectable({providedIn: 'any'})
export class ApplicationRoutes extends RouteBuilder {
    selection = () => this.url('selection');
    details = (id: IdType) => this.urlFromCommands([id]);
    in = (id: IdType | undefined) => this.childRoutes(String(id), ApplicationDetailsRoutes);
}

@Injectable({providedIn: 'any'})
export class ApplicationDetailsRoutes extends RouteBuilder {
    actor = this.childRoutes('actor', ActorRoutes);
    dataModel = this.childRoutes('datamodel', DataModelRoutes);
    feature = this.childRoutes('feature', FeatureRoutes);
    form = this.childRoutes('form', FormRoutes);
    functionalRequirement = this.childRoutes('functionalrequirement', FunctionalRequirementRoutes);
    glossaryTerm = this.childRoutes('glossaryterm', GlossaryTermRoutes);
    nonFunctionalRequirement = this.childRoutes('nonfunctionalrequirement', NonFunctionalRequirementRoutes);
    page = this.childRoutes('page', PageRoutes);
    table = this.childRoutes('table', TableRoutes);
    useCase = this.childRoutes('usecase', UseCaseRoutes);
    appComponent = this.childRoutes('appcomponent', AppComponentRoutes);
    discussion = this.childRoutes('discussion', DiscussionRoutes);
}
