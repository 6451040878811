import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {Observable, filter, map, switchMap, tap} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {PasswordResetService} from './password-reset.service';

export interface PasswordResetState {
    token?: string;
    requesting: boolean;
    requestSuccess: boolean;
    error?: string;
}

const initialState: PasswordResetState = {
    requesting: false,
    requestSuccess: false,
};

@Injectable()
export class PasswordResetStore extends ComponentStore<PasswordResetState> {
    // Selectors
    readonly selectToken$: Observable<string | undefined> = this.select(state => state.token);
    readonly selectError$: Observable<string | undefined> = this.select(state => state.error);
    readonly selectRequesting$: Observable<boolean> = this.select(state => state.requesting);
    readonly selectRequestSuccess$: Observable<boolean> = this.select(state => state.requestSuccess);

    // Effects
    readonly sendPasswordResetRequest = this.effect((reset$: Observable<string>) => {
        return reset$.pipe(
            filter(() => undefined !== this.get().token),
            tap(() => this.onRequest()),
            map(password => ({token: this.get().token ?? '', password})),
            switchMap((data: { token: string; password: string }) =>
                this.passwordResetService.resetPassword(data.token, data.password).pipe(
                    tapResponse(
                        () => this.onResponse(),
                        (error: HttpErrorResponse) => {
                            switch (error.status) {
                                case 404:
                                    this.onError('ERROR.USER_NOT_FOUND');
                                    break;

                                case 422:
                                    this.onError('ERROR.PASSWORD_CHARACTERS');
                                    break;

                                default:
                                    this.onError('ERROR.UNABLE_CHANGE_PASSWORD');
                                    break;
                            }
                        }
                    )
                )
            )
        );
    });

    // Reducers
    readonly setToken = this.updater((state, token: string) => ({
        ...state,
        token,
    }));
    readonly onError = this.updater((state, error: string) => ({
        ...state,
        error,
        requesting: false,
    }));
    readonly onRequest = this.updater(state => ({
        ...state,
        error: undefined,
        requesting: true,
        requestSuccess: false,
    }));
    readonly onResponse = this.updater(state => ({
        ...state,
        error: undefined,
        requesting: false,
        requestSuccess: true,
    }));

    constructor(private readonly passwordResetService: PasswordResetService) {
        super(initialState);
    }
}
