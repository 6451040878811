import {Application, ApplicationStore} from '@application/data';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {combineLatest, filter, map, switchMap} from 'rxjs';
import {AppRoutes} from '@routing';
import {DrawerService} from '@ui/drawer';
import {IdType} from '@axiocode/entity';
import {Organization} from '@organization/data';
import {OrganizationStore} from '@organization/data';
import {PermissionService} from '@user/security';
import {UserStore} from '@user/data';
import {modelsEqualityById} from '@utils';

@Component({
    selector: 'toolbar-application-switcher',
    template: `
    <div class="row">
        <div class="switcher-select" *ngIf="filteredApplications$ | async as applications" style="width:100%;">
            <mat-form-field>
                <mat-label>{{ 'APPLICATION.APPLICATION' | translate }}</mat-label>
                <mat-select [compareWith]="compare" [(value)]="currentApplication">
                    <mat-option
                        *ngFor="let application of applications"
                        (click)="changeApplication(application)"
                        [value]="application">
                        {{ application.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationSwitcherComponent {

    @Input() currentApplication?: Application;

    filteredApplications$ = combineLatest([this.organizationStore.selectSelectedEntity$, this.userStore.selectCurrentUser$]).pipe(
        map(([organization, user]) => organization),
        filter((currentOrganization): currentOrganization is Organization => !!currentOrganization),
        switchMap(currentOrganization => this.applicationStore.selectApplicationsByOrganization$(currentOrganization)),
        map(applications => applications.filter(application => this.permissionService.isGranted({target: application, permission: 'read', type: 'application'}))),
        map(applications => applications.sort((a, b) => a.name.localeCompare(b.name))),
    );

    compare = modelsEqualityById;

    constructor(
        public routes: AppRoutes,
        private applicationStore: ApplicationStore,
        private organizationStore: OrganizationStore,
        private userStore: UserStore,
        private drawerService: DrawerService,
        private permissionService: PermissionService,
    ) {
        this.applicationStore.findAll();
    }

    changeApplication(application: {id: IdType}): void {
        this.drawerService.closeDrawer().then(() => this.routes.application.details(application.id).navigate());
    }
}

