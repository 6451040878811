import {Component, Input} from '@angular/core';

import {ActionButtonComponent} from './action-button.component';

@Component({
    selector: 'ui-save-button',
    templateUrl: './action-button.component.html'
})
export class SaveButtonComponent extends ActionButtonComponent {

    @Input() override color = 'primary';
    @Input() override buttonType: 'normal' | 'stroked' | 'raised' | 'icon' | 'menu-item' = 'normal';

}
