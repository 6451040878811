import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'toolbar-lang-switcher',
    templateUrl: './lang-switcher.component.html',
})
export class LangSwitcherComponent {

    languages: Language[] = [
        {value: 'fr', viewValue: 'LANGUAGE.FRENCH'},
        {value: 'en', viewValue: 'LANGUAGE.ENGLISH'},
    ];
    selectedValue: string | null = null;
    // Trick to help material to display label in select.
    // Added | async to wait translations loading
    loaded$ = this.translate.get('LANGUAGE.FRENCH');

    constructor(private translate: TranslateService) {
        this.selectedValue = window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'fr';
    }

    changeLanguage(selectedValue: string) {
        window.localStorage.setItem('lang', selectedValue);
        this.translate.use(selectedValue);
    }

}

interface Language {
    value: string;
    viewValue: string;
}
