import {
    AbstractControl,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';

/** @ignore */
interface NewPasswordForm {
    password: FormControl<string>;
    confirm: FormControl<string>;
}

/** @ignore */
const identicalPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirm = control.get('confirm');

    return password && confirm && password.value === confirm.value ? null : {differentPasswords: true};
};

/** Material StateMatcher to display the form (parent) error on the confirm password field */
class ParentErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = !!(form && form.submitted);
        const controlTouched = !!(control && (control.dirty || control.touched));
        const controlInvalid = !!(control && control.invalid);
        const parentInvalid = !!(
            control &&
            control.parent &&
            control.parent.invalid &&
            (control.parent.dirty || control.parent.touched)
        );

        return isSubmitted || (controlTouched && (controlInvalid || parentInvalid));
    }
}

@Component({
    selector: 'auth-password-reset-form',
    templateUrl: './password-reset-form.component.html',
    styleUrls: ['./password-reset-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordResetFormComponent {
    /** Set to true if the HTTP request is ongoing */
    @Input() loading = false;
    /** If an error happened */
    @Input() error?: string;
    /** The login page URL */
    @Input() loginUrl?: string;

    /** Emits the form when it is submitted */
    @Output() submitted: EventEmitter<string> = new EventEmitter();

    /** @ignore */
    form: FormGroup<NewPasswordForm> = new FormGroup<{
        password: FormControl<string>;
        confirm: FormControl<string>;
    }>(
        {
            password: new FormControl<string>('', {
                nonNullable: true,
                validators: [
                    Validators.required,
                    Validators.pattern(
                        /^(?=.*\p{L})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*.!@$%^&#"'(){}[\]:;<>,?/~_+\-=°|])[\p{L}\d*.!@$%^&#"'(){}[\]:;<>,?/~_+\-=°|]{9,}$/u
                    ),
                ],
            }),
            confirm: new FormControl<string>('', {
                nonNullable: true,
                validators: [Validators.required],
            }),
        },
        {validators: identicalPasswordValidator}
    );
    /** @ignore */
    parentErrorStateMatcher = new ParentErrorStateMatcher();

    /** Hide password in the field */
    hide = true;

    /** @ignore */
    public submit(): void {
        if (this.form.valid) {
            const data = this.form.getRawValue();
            this.submitted.next(data.password);
        }
    }
}
