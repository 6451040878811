import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'auth-password-forgotten-form',
    templateUrl: './password-forgotten-form.component.html',
    styleUrls: ['./password-forgotten-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordForgottenFormComponent {
    /** Set to true if the HTTP request is ongoing */
    @Input() loading = false;
    /** If an error happened */
    @Input() error?: string;
    /** The login page URL */
    @Input() loginUrl?: string;

    /** Emits the form when it is submitted */
    @Output() submitted: EventEmitter<string> = new EventEmitter();

    form: FormGroup<{ email: FormControl<string> }> = new FormGroup<{
        email: FormControl<string>;
    }>({
        email: new FormControl<string>('', {
            nonNullable: true,
            validators: [Validators.required, Validators.email],
        }),
    });

    public submit(): void {
        if (this.form.valid) {
            const data = this.form.getRawValue();
            this.submitted.emit(data.email);
        }
    }
}
