import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HotkeysModule} from '@ui/hotkeys';
import {MaterialModule} from '@ui/material';
import {NgModule} from '@angular/core';
import {PipesModule} from '@ui/pipes';
import {RouterModule} from '@angular/router';
import {SecurityModule} from '@user/security';
import {TranslateModule} from '@ngx-translate/core';

import {ApplicationSwitcherComponent} from './components/application-toolbar/application-switcher/application-switcher.component';
import {
    ApplicationToolbarComponent
} from './components/application-toolbar/application-toolbar.component';
import {GlobalSearchComponent} from './components/global-search/global-search.component';
import {LangSwitcherComponent} from './components/application-toolbar/lang-switcher/lang-switcher.component';
import {OrganizationSwitcherComponent} from './components/application-toolbar/organization-switcher/organization-switcher.component';

@NgModule({
    imports: [
        CommonModule,
        HotkeysModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule,
        SecurityModule,
        TranslateModule.forChild(),
        PipesModule,
        FormsModule
    ],
    declarations: [
        ApplicationSwitcherComponent,
        ApplicationToolbarComponent,
        GlobalSearchComponent,
        LangSwitcherComponent,
        OrganizationSwitcherComponent,
    ],
    exports: [ApplicationToolbarComponent],
})
export class ToolbarModule {}
