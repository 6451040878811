<form (ngSubmit)="submit()" [formGroup]="loginForm" name="loginForm">
    <mat-card-content>
        <mat-form-field appearance="outline">
            <mat-label>{{ "LOGIN.LOGIN_ID" | translate }}</mat-label>
            <input
                id="login"
                name="login"
                data-cy="input-email"
                formControlName="username"
                matInput
                required
            />
            <mat-error
                *ngIf="loginForm.get('username')?.hasError('required')"
                data-cy="error-input-login-required"
            >
                {{ "ERROR.LOGIN_REQUIRED" | translate }}
            </mat-error>
            <mat-error
                *ngIf="loginForm.get('username')?.hasError('email')"
                data-cy="error-input-login-email"
            >
                {{ "ERROR.EMAIL_NOT_VALID" | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{ "LOGIN.PASSWORD" | translate }}</mat-label>
            <input
                [type]="hidePassword ? 'password' : 'text'"
                required
                data-cy="input-password"
                formControlName="password"
                matInput
                name="password"
            />
            <button
                type="button"
                (click)="hidePassword = !hidePassword"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hidePassword"
                mat-icon-button
                matSuffix
                data-cy="hide-password-button"
            >
                <mat-icon>{{
                    hidePassword ? "visibility_off" : "visibility"
                }}</mat-icon>
            </button>
            <mat-error
                *ngIf="loginForm.get('password')?.hasError('required')"
                data-cy="error-input-password-required"
            >
                {{ "ERROR.PASSWORD_REQUIRED" | translate }}
            </mat-error>
        </mat-form-field>
    </mat-card-content>
    <div class="text-center">
        <div>
            <button
                class="login-button"
                color="primary"
                type="submit"
                data-cy="submit"
                [disabled]="loginForm.invalid || loading"
                mat-raised-button
            >
                {{ "LOGIN.TO_LOG_IN" | translate }}
            </button>
        </div>
    </div>
</form>
