import {LoginComponent, PasswordForgottenComponent, PasswordResetComponent, authGuard} from '@user/auth';
import {Route} from '@angular/router';
import {applicationResolver} from '@application/data';

import {NotFoundComponent} from './not-found.component';

export const appRoutes: Route[] = [
    {
        path: 'auth',
        children: [
            {
                path: 'login',
                pathMatch: 'full',
                component: LoginComponent,
                data: {title: 'LOGIN.LOGIN'},
            },
            {
                path: 'password-forgotten',
                pathMatch: 'full',
                component: PasswordForgottenComponent,
                data: {title: 'LOGIN.FORGOTTEN_PASSWORD'},
            },
            {
                path: 'password-reset/:token',
                pathMatch: 'full',
                component: PasswordResetComponent,
                data: {title: 'LOGIN.RESET_PASSWORD'},
            },
        ],
    },
    {
        path: 'application',
        canActivate: [authGuard],
        loadChildren: () => import('@application/feature').then(m => m.ApplicationFeatureModule),
    },
    {
        path: 'organization',
        canActivate: [authGuard],
        loadChildren: () => import('@organization/feature').then(m => m.OrganizationFeatureModule),
    },
    {
        path: 'user',
        canActivate: [authGuard],
        loadChildren: () => import('@user/feature').then(m => m.UserFeatureModule),
    },
    {
        path: 'preview/application',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@application/feature').then(m => m.ApplicationFeatureModule),
    },
    {
        path: 'preview/actor',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@actor/feature').then(m => m.ActorFeatureModule),
    },
    {
        path: 'preview/feature',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@feature/feature').then(m => m.FeatureFeatureModule),
    },
    {
        path: 'preview/functionalrequirement',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@functional-requirement/feature').then(m => m.FunctionalRequirementFeatureModule),
    },
    {
        path: 'preview/nonfunctionalrequirement',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () =>
            import('@non-functional-requirement/feature').then(m => m.NonFunctionalRequirementFeatureModule),
    },
    {
        path: 'preview/datamodel',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@data-model/feature').then(m => m.DataModelFeatureModule),
    },
    {
        path: 'preview/page',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@page/feature').then(m => m.PageFeatureModule),
    },
    {
        path: 'preview/usecase',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@use-case/feature').then(m => m.UseCaseFeatureModule),
    },
    {
        path: 'preview/form',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@form/feature').then(m => m.FormFeatureModule),
    },
    {
        path: 'preview/glossaryterm',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@glossary-term/feature').then(m => m.GlossaryTermFeatureModule),
    },
    {
        path: 'preview/table',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@table/feature').then(m => m.TableFeatureModule),
    },
    {
        path: 'preview/relation',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@data-model/feature').then(m => m.DataModelFeatureModule),
    },
    {
        path: 'preview/applicationcomponent',
        resolve: {application: applicationResolver},
        canActivate: [authGuard],
        outlet: 'drawer',
        data: {preview: true},
        loadChildren: () => import('@app-component/feature').then(m => m.AppComponentFeatureModule),
    },
    {path: 'login', redirectTo: '/auth/login', pathMatch: 'full'},
    {path: 'passwordreset/:token', redirectTo: '/auth/password-reset/:token'}, // retro compatibility
    {path: '', redirectTo: '/application/selection', pathMatch: 'full'},
    {path: '**', pathMatch: 'full', component: NotFoundComponent, data: {title: 'BREADCRUMB.TYPES.ERROR'}},
];
