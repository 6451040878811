import {ApiConfig, ApiToken} from '@token';
import {CacheBucket, withCache} from '@ngneat/cashew';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {ConvertSnakeToCamelCase} from '@axiocode/entity';
import {HttpClient} from '@angular/common/http';

import {ApiConfiguration, Configuration, mapConfigurations} from '../models/configuration.interface';

/**
 * This services fetches Managician configurations from the server and returns them as a Configuration object.
 */
@Injectable({providedIn: 'root'})
export class ConfigurationProvider {
    /** @ignore */
    protected cacheBucket = new CacheBucket();

    /** The cache duration in milliseconds. Override it to change cache duration. */
    protected ttl = 1200000;

    /** @ignore */
    constructor(
        @Inject(ApiToken) private readonly config: ApiConfig,
        private http: HttpClient,
        // eslint-disable-next-line no-empty-function
    ) {}

    /**
     * Fetches all the configurations from the server and return them as an object.
     * The HTTP call is cached.
     */
    public findAll$(): Observable<Configuration> {
        return this.http.get<ApiConfiguration>(`${this.config.apiBaseUrl}/api/configurations`, {
            context: withCache({
                cache: true,
                bucket: this.cacheBucket,
                ttl: this.ttl,
            }).set(ConvertSnakeToCamelCase, true),
        }).pipe(
            map(conf => mapConfigurations(conf.configurations)),
        );
    }
}
