import {CodeFormatterService, SimpleVersionable, Versionable, VersionableType} from '@versionable/data';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Mention, MentionConversionService, MentionStore} from '@mention/data';
import {Observable, Subject, combineLatest, distinctUntilChanged, map, skipUntil, tap} from 'rxjs';
import {Scenario, ScenarioStep, UseCase, UseCaseStore} from '@use-case/data';
import {ActorStore} from '@actor/data';
import {Application} from '@application/data';
import {DataModelStore} from '@data-model/data';
import {FeatureStore} from '@feature/data';
import {FormStore} from '@form/data';
import {FunctionalRequirementStore} from '@functional-requirement/data';
import {GlossaryTermStore} from '@glossary-term/data';
import {IdType} from '@axiocode/entity';
import {NonFunctionalRequirementStore} from '@non-functional-requirement/data';
import {PageStore} from '@page/data';
import {Router} from '@angular/router';
import {TableStore} from '@table/data';
import {UntypedFormControl} from '@angular/forms';

import {SearchResult} from '../../models/search-result.model';
import {ToolbarLocalStore} from '../../+state/toolbar-local.store';
import {WeightedSearchService} from '../../services/weighted-search.service';

@Component({
    selector: 'toolbar-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {

    @Input() application?: Application = undefined;
    @ViewChild('searchFieldHtml', {read: ElementRef}) searchFieldHtml?: ElementRef = undefined;

    private searchField$: Subject<string> = new Subject<string>();
    private versionableGlobalState$: Observable<SimpleVersionable[]>;
    private results: SearchResult[] = [];

    searchField: UntypedFormControl = new UntypedFormControl();
    results$: Observable<SearchResult[]>;
    focusResult = false;
    mouseEnterResult = true;
    query = '';
    selectedIndex = 0;

    constructor(
        private store: ToolbarLocalStore,
        private dataModelStore: DataModelStore,
        private actorStore: ActorStore,
        private formStore: FormStore,
        private tableStore: TableStore,
        private glossaryTermStore: GlossaryTermStore,
        private featureStore: FeatureStore,
        private functionalRequirementStore: FunctionalRequirementStore,
        private nonFunctionalRequirementStore: NonFunctionalRequirementStore,
        private pageStore: PageStore,
        private useCaseStore: UseCaseStore,
        private mentionStore: MentionStore,
        private codeFormatterService: CodeFormatterService,
        private mentionConversionService: MentionConversionService,
        private router: Router,
        // eslint-disable-next-line no-empty-function
    ) {
        this.versionableGlobalState$ = combineLatest([
            this.mentionStore.selectMentions$,
            this.dataModelStore.selectAll$,
            this.actorStore.selectAll$,
            this.formStore.selectAll$,
            this.tableStore.selectAll$,
            this.glossaryTermStore.selectAll$,
            this.featureStore.selectAll$,
            this.functionalRequirementStore.selectAll$,
            this.nonFunctionalRequirementStore.selectAll$,
            this.pageStore.selectAll$]
        ).pipe(
            map(([
                mentions,
                dataModels,
                actors,
                forms,
                tables,
                glossaryTerms,
                features,
                functionalRequirements,
                nonFunctionalRequirements,
                pages
            ]) => [
                ...this.convertVersionablesDescription(dataModels, 'datamodel', 'datamodel', mentions),
                ...this.convertVersionablesDescription(actors, 'actor', 'actor', mentions),
                ...this.convertVersionablesDescription(forms, 'form', 'form', mentions),
                ...this.convertVersionablesDescription(tables, 'table', 'table', mentions),
                ...this.convertVersionablesDescription(glossaryTerms, 'glossaryterm', 'glossaryterm', mentions),
                ...this.convertVersionablesDescription(features, 'feature', 'feature', mentions),
                ...this.convertVersionablesDescription(functionalRequirements, 'functionalrequirement', 'functionalrequirement', mentions),
                ...this.convertVersionablesDescription(nonFunctionalRequirements, 'nonfunctionalrequirement', 'nonfunctionalrequirement', mentions),
                ...this.convertVersionablesDescription(pages, 'page', 'page', mentions),
            ])
        );

        this.results$ = combineLatest([
            this.searchField$,
            this.versionableGlobalState$,
            this.useCaseStore.selectAll$,
            this.mentionStore.selectMentions$,
        ]).pipe(
            // Ignore everything until the search field has actually changed
            skipUntil(this.searchField$),
            distinctUntilChanged(),
            tap(([search]) => this.query = search),
            map(([search, global, useCases, mentions]) => {

                return [search, global, this.convertUseCasesDescriptions(useCases, mentions)];
            }),
            // Search filter
            map(([search, global, useCases]) => {
                const engine = new WeightedSearchService();

                return [
                    ...engine.search(global as Versionable[], search as string, [
                        {field: 'name', weight: 2},
                        {field: 'codeAndPrefix', weight: 2},
                        {field: 'description', weight: 1},
                    ]),
                    ...engine.search(useCases as UseCase[], search as string, [
                        {field: 'name', weight: 2},
                        {field: 'codeAndPrefix', weight: 2},
                        {field: 'description', weight: 1},
                        {field: 'preConditions', weight: 1},
                        {field: 'postConditions', weight: 1},
                        {field: 'scenarios.name', weight: 1},
                        {field: 'scenarios.description', weight: 1},
                        {field: 'scenarios.scenarioSteps.description', weight: 1},
                    ])
                ];
            }),
            // Limit the number of results
            map(results => results.slice(0, 15)),
            tap(results => {
                this.results = results;
                this.selectedIndex = 0;
            }),
        );
    }

    ngOnInit(): void {
        this.searchField.valueChanges.subscribe(value => this.searchField$.next(value));
    }

    refreshSearchState(): void {
        if (!this.focusResult && !this.mouseEnterResult) {
            this.store.setIsSearchOpened(false);
        }
    }

    trackResult(index: number, item: SearchResult): IdType {
        return item.id;
    }

    clearInputField(): void {
        this.searchField.setValue('');
    }

    focusInput(): void {
        this.focusResult = !this.focusResult;
        this.refreshSearchState();
    }

    searchMouseEventIn(): void {
        this.mouseEnterResult = true;
        this.refreshSearchState();
    }

    searchMouseEventOut(): void {
        this.mouseEnterResult = false;
        this.refreshSearchState();
    }

    navigateTo(type: string | undefined, id: number | undefined): void {
        this.router.navigate(['/', 'application', this.application?.id, type, id]);
    }

    openPreview(type: string | undefined, id?: IdType): void {
        if (id && type) {
            this.router.navigate(['', {
                outlets: {drawer: ['preview', type, id]}
            }]);
        }
    }

    onKeyDown(event: KeyboardEvent): void {
        if ('ArrowUp' === event.key) {
            event.preventDefault();
            event.stopImmediatePropagation();

            if (0 === this.selectedIndex) {
                // If we're on top of the list and hit "ArrowUp", focus/show the search field again
                this.searchFieldHtml?.nativeElement.scrollIntoView();
            } else {
                // Select next element and make sure we scroll on it so that it's visible
                this.selectedIndex = Math.max(0, this.selectedIndex - 1);
                document.getElementById('search-result-' + this.selectedIndex)?.scrollIntoView();
            }
        } else if ('ArrowDown' === event.key) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.selectedIndex = Math.min(this.results.length - 1, this.selectedIndex + 1);
            document.getElementById('search-result-' + this.selectedIndex)?.scrollIntoView();
        } else if ('Enter' === event.key) {
            if (this.results.length && this.focusResult) {
                const selectedItem = this.results[this.selectedIndex];
                this.navigateTo(
                    selectedItem.typeClass?.toString()?.toLowerCase(),
                    selectedItem.id
                );
            }
        } else if ('Escape' === event.key) {
            this.store.setIsSearchOpened(false);
        }
    }

    // Used to convert all descriptions in versionables
    convertVersionablesDescription(versionables: Versionable[], typeClass: string, typeCode: VersionableType, mentions: Mention[]): SimpleVersionable[] {
        let newVersionables: SimpleVersionable[] = [];
        versionables.forEach(versionable => {
            let newVersionable = {
                id: versionable.id,
                name: versionable.name,
                code: versionable.code,
                codeAndPrefix: this.codeFormatterService.format(versionable, typeCode, true),
                description: versionable.description ? this.mentionConversionService.replaceMentionsInString(mentions, versionable.description, true) : '',
                typeClass: typeClass
            };
            newVersionables.push(newVersionable);
        });

        return newVersionables;
    }

    // Used to convert all descriptions in useCases
    convertUseCasesDescriptions(useCases: UseCase[], mentions: Mention[]): Partial<UseCase>[] {
        let newUseCases: Partial<UseCase>[] = [];
        useCases.forEach(useCase => {
            let scenarios: Scenario[] = [];

            useCase.scenarios?.forEach(scenario => {
                let steps: ScenarioStep[] = [];
                scenario.scenarioSteps?.forEach(step => {
                    let newStep = {
                        id: step.id,
                        description: step.description ? this.mentionConversionService.replaceMentionsInString(mentions, step.description, true) : '',
                    };
                    steps.push(newStep);
                });
                let newScenario = {
                    id: scenario.id,
                    description: scenario.description ? this.mentionConversionService.replaceMentionsInString(mentions, scenario.description, true) : '',
                    type: scenario.type,
                    scenarioChildren: scenario.scenarioChildren,
                    isRootScenario: scenario.isRootScenario,
                    scenarioSteps: steps
                };

                scenarios.push(newScenario);
            });

            let newUseCase: Partial<UseCase> = {
                id: useCase.id,
                name: useCase.name,
                code: useCase.code,
                codeAndPrefix: this.codeFormatterService.format(useCase, 'usecase', true),
                description: useCase.description ? this.mentionConversionService.replaceMentionsInString(mentions, useCase.description, true) : '',
                postConditions: useCase.postConditions ? this.mentionConversionService.replaceMentionsInString(mentions, useCase.postConditions, true) : '',
                preConditions: useCase.preConditions ? this.mentionConversionService.replaceMentionsInString(mentions, useCase.preConditions, true) : '',
                scenarios: scenarios,
                requirementTags: useCase.requirementTags,
                useCaseType: useCase.useCaseType,
                nbActions: useCase.nbActions,
                actions: useCase.actions,
                typeClass: 'usecase',
                features: useCase.features,
            };
            newUseCases.push(newUseCase);
        });

        return newUseCases;
    }
}
