import {CommonModule} from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {ApplicationLoaderComponent} from './components/application-loader/application-loader.component';
import {LoaderComponent} from './components/loader/loader.component';

@NgModule({
    imports: [CommonModule, MatProgressBarModule, MatProgressSpinnerModule, TranslateModule],
    declarations: [ApplicationLoaderComponent, LoaderComponent],
    exports: [ApplicationLoaderComponent, LoaderComponent],
})
export class LoaderModule {}
