import * as Sentry from '@sentry/angular-ivy';
import {ErrorHandler, Inject, Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';

import {AxioError, AxioFormError} from '../models/error';
import {AxiocodeErrorHandlerModuleConfig, AxiocodeErrorHandlerModuleToken} from '../token';

/**
 * If you want to react to errors when they happen, you can inject this class into one of your component or service
 * using @Inject(ErrorHandler):
 *
 * @example
 * class MyService {
 *     private error$ = this.errorHandler.error$;
 *     constructor(@Inject(ErrorHandler) private errorHandler: ErrorHandlerService) {}
 * }
 */
@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

    private errorSubject: Subject<AxioError> = new Subject<AxioError>();

    /**
     * This observable emits values every time a AxioError is emitted in an application.
     */
    get error$(): Observable<AxioError> {
        return this.errorSubject.asObservable();
    }

    constructor(@Inject(AxiocodeErrorHandlerModuleToken) private config: AxiocodeErrorHandlerModuleConfig) {
        super();
    }

    /**
     * This method will catch every thrown errors that have not been properly caught.
     * We can use it to notify Sentry about a new error, or display the error to the user, etc.
     */
    override handleError(error: any): AxioError | void {
        switch (true) {
            case error instanceof AxioError:
                return this.handleAxioError(error as AxioError);

            case error instanceof HttpErrorResponse:
                return this.handleHttpError(error as HttpErrorResponse);

            default:
                this.sendError(error);
                break;
        }
    }

    applyFormErrors(errors: AxioFormError[], form: UntypedFormGroup) {
        errors.forEach(error => {
            // We replace the [n] in the api field path by .n
            const fieldPath = error.field?.replace(/\[([0-9+])\]/, '.$1');
            const field = form.get(fieldPath as string);
            field?.setErrors({api: error.message});
            field?.markAsTouched();
        });
    }

    private sendError(error: unknown): void {
        if (this.config.debug) {
            console.error(error);
        }

        // Send the unknown error to Sentry.
        Sentry.captureException(error);
    }

    private handleAxioError(error: AxioError): AxioError {
        this.errorSubject.next(error);

        if (this.config.debug) {
            console.error(error.message);
            console.error(error.stack);
        }

        return error;
    }

    private handleHttpError(error: HttpErrorResponse): AxioError | void {
        // If the error is HttpErrorResponse 401 because the client is no longed logged in, we simply dismiss the error.
        if (error instanceof HttpErrorResponse && 401 === error.status) {
            this.handleAxioError(new AxioError('ERROR.LOGGED_OUT_USER', error.url ?? '', true));

            return;
        }

        let message = error.message;
        // ApiBundle returns some errors as an array of {message: string}.
        if (Array.isArray(error.error) && error.error.length > 0) {
            message = error.error[0].message; // We only take the first error message.
        }

        const axioError = new AxioError(message, error.url ?? '');

        if (400 === error.status && Array.isArray(error.error) && error.error.length) {
            const formErrors: AxioFormError[] = [];
            error.error.forEach(e => {
                formErrors.push({
                    field: e.field,
                    message: e.message
                });
            });
            axioError.formErrors = formErrors;

            return this.handleAxioError(axioError); // We don't want a Sentry report if this is a form error
        }

        // If the error code is ignored, we don't send it to Sentry.
        if (!this.config.ignoreErrorCodes.includes(error.status)) {
            this.sendError(error);
        }

        return this.handleAxioError(axioError);
    }

}
