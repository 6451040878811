import {ErrorHandler, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AxiocodeErrorHandlerModuleConfig, AxiocodeErrorHandlerModuleToken} from './token';
import {ErrorHandlerService} from './services/error-handler.service';

/**
 * Import this module in the main module of your application (usually AppModule).
 * Use the configuration to display or hide the errors in the console.
 *
 * @example
 *  @NgModule({
 *     declarations: [...],
 *     imports: [
 *         ...,
 *         AxiocodeErrorHandlerModule.forRoot({ debug: !environment.production }),
 *     ]
 * })
 * export class AppModule {}
 */
@NgModule({
    imports: [CommonModule],
})
export class AxiocodeErrorHandlerModule {
    static forRoot(
        config: AxiocodeErrorHandlerModuleConfig
    ): ModuleWithProviders<AxiocodeErrorHandlerModule> {
        return {
            ngModule: AxiocodeErrorHandlerModule,
            providers: [
                ErrorHandlerService,
                {provide: AxiocodeErrorHandlerModuleToken, useValue: config},
                {provide: ErrorHandler, useClass: ErrorHandlerService}
            ]
        };
    }
}
