import {Application, ApplicationLoaderService, ApplicationStore} from '@application/data';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject, distinctUntilChanged, map, tap} from 'rxjs';
import {FeatureStore} from '@feature/data';
import {HttpErrorResponse} from '@angular/common/http';
import {SubSink} from 'subsink';
import {sortByCode} from '@utils';

import {UseCase} from '../models/use-case.model';
import {UseCaseProvider} from './use-case.provider';
import {UseCaseState} from '../models/use-case-state.interface';

export function initializeApplicationLoader(service: ApplicationLoaderService, provider: UseCaseProvider, store: UseCaseStore) {
    return () => service.registerLoader((app: Application) => provider.findAll$().pipe(tap(data => store.upsertMany(data))));
}

@Injectable({providedIn: 'root'})
export class UseCaseStore extends EntityStore<UseCase, UseCaseState> implements OnDestroy {
    #created = new Subject<EntitiesChange<UseCase>>();
    get created(): Observable<EntitiesChange<UseCase>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<UseCase>>();
    get updated(): Observable<EntitiesChange<UseCase>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<UseCase>>();
    get deleted(): Observable<EntitiesChange<UseCase>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    /** @ignore */
    private subs = new SubSink();

    // Callbacks
    override afterCreate = (data: UseCase) => this.applicationStore.refreshCurrentApplication$.pipe(map(() => data));

    // Selectors
    readonly selectAllUseCasesByFeature$ = (feature: {id: IdType}) => this.select(
        this.selectAll$,
        useCases => useCases.filter(useCase => useCase.feature?.id === feature.id)
    );
    readonly selectAllUseCasesByActor$ = (actor: {id: IdType}) => this.select(
        this.selectAll$,
        useCases => useCases.filter(useCase => useCase.actor?.id === actor.id)
    );

    public override onSuccess(change: EntitiesChange<UseCase>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<UseCase, UseCaseState> {
        return {
            storeName: 'UseCaseStore',
            initialState: {ids: [], entities: {}, currentUseCaseActions: []},
            selectId: useCase => useCase.id,
            sort: sortByCode,
        };
    }

    constructor(
        provider: UseCaseProvider,
        private applicationStore: ApplicationStore,
        private featureStore: FeatureStore,
    ) {
        super(provider);

        this.subs.sink = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();

        // Refreshes the use cases when a feature is deleted
        this.subs.sink = this.featureStore.deleted.pipe(
            tap(() => {
                this.provider.resetCache();
                this.findAll();
            })
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subs.unsubscribe();
    }
}
