import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {Observable, Subject, filter, switchMap, take, tap} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Application} from '../models/application.model';
import {ApplicationProvider} from './application.provider';
import {ApplicationState} from '../models/application-state.interface';

@Injectable({providedIn: 'root'})
export class ApplicationStore extends EntityStore<Application, ApplicationState> {
    #created = new Subject<EntitiesChange<Application>>();
    get created(): Observable<EntitiesChange<Application>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Application>>();
    get updated(): Observable<EntitiesChange<Application>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Application>>();
    get deleted(): Observable<EntitiesChange<Application>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Effects
    readonly refreshCurrentApplication$ = this.selectSelectedEntity$.pipe(
        filter((application): application is Application => undefined !== application),
        take(1),
        tap(() => this.provider.resetCache()),
        switchMap(application => this.provider.findOne$(application.id).pipe(
            tap(entity => this.upsertOne(entity)),
        )),
    );
    readonly refreshCurrentApplication = this.effect(trigger$ => trigger$.pipe(
        switchMap(() => this.refreshCurrentApplication$)
    ));

    // Selectors
    readonly selectApplicationsByOrganization$ = (organization: {id: IdType}) => this.select(
        this.selectAll$,
        applications => applications?.filter(applications => applications.organization?.id === organization.id)
    );

    public override onSuccess(change: EntitiesChange<Application>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Application, ApplicationState> {
        return {
            storeName: 'ApplicationStore',
            initialState: {ids: [], entities: {}},
            selectId: app => app.id,
        };
    }

    constructor(provider: ApplicationProvider) {
        super(provider);
    }
}
