import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {DisplayNamePipe} from './display-name.pipe';
import {DisplayNicknamePipe} from './display-nickname.pipe';
import {HighlightPipe} from './highlight.pipe';
import {LocalizedDatePipe} from './localized-date.pipe';
import {MentionConverterPipe} from './mention-converter.pipe';
import {NoHtmlPipe} from './no-html.pipe';
import {NoSanitizePipe} from './no-sanitize.pipe';
import {PluralTranslatePipe} from './plural-translate.pipe';
import {TruncateHtmlPipe} from './truncate-html.pipe';
import {TruncatePipe} from './truncate.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        DisplayNamePipe,
        DisplayNicknamePipe,
        HighlightPipe,
        LocalizedDatePipe,
        MentionConverterPipe,
        NoHtmlPipe,
        NoSanitizePipe,
        PluralTranslatePipe,
        TruncateHtmlPipe,
        TruncatePipe,
    ],
    exports: [
        DisplayNamePipe,
        DisplayNicknamePipe,
        HighlightPipe,
        LocalizedDatePipe,
        MentionConverterPipe,
        NoHtmlPipe,
        NoSanitizePipe,
        PluralTranslatePipe,
        TruncateHtmlPipe,
        TruncatePipe,
    ],
})
export class PipesModule {}
