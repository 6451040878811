import {DEFAULT_ROUTER_FEATURENAME, StoreRouterConnectingModule, routerReducer} from '@ngrx/router-store';
import {EnvironmentProviders, ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule} from '@abacritt/angularx-social-login';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MaterialModule} from '@ui/material';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';

import {AuthModuleConfig, AuthModuleToken} from './token';
import {AuthService} from './services/auth.service';
import {AuthStore} from './services/auth.store';
import {CredentialsInterceptor} from './interceptors/credentials.interceptor';
import {GoogleButtonComponent} from './components/google-button/google-button.component';
import {LanguageInterceptor} from './interceptors/language.interceptor';
import {LoginComponent} from './components/login/login.component';
import {LoginFormComponent} from './components/login/login-form/login-form.component';
import {PasswordForgottenComponent} from './components/password-forgotten/password-forgotten.component';
import {PasswordForgottenFormComponent} from './components/password-forgotten/password-forgotten-form/password-forgotten-form.component';
import {PasswordResetComponent} from './components/password-reset/password-reset.component';
import {PasswordResetFormComponent} from './components/password-reset/password-reset-form/password-reset-form.component';
import {PasswordResetService} from './services/password-reset.service';
import {RefreshTokenInterceptor} from './interceptors/refresh-token.interceptor';
import {authStateReducer} from './services/debug/auth-debug.store';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule,
        SocialLoginModule,
        GoogleSigninButtonModule,
        StoreModule.forFeature({name: DEFAULT_ROUTER_FEATURENAME, reducer: routerReducer}),
        StoreModule.forFeature({name: 'authState', reducer: authStateReducer}),
        StoreRouterConnectingModule.forRoot(),
        TranslateModule.forChild(),
        MatSidenavModule,
    ],
    declarations: [
        LoginComponent,
        LoginFormComponent,
        GoogleButtonComponent,
        PasswordForgottenComponent,
        PasswordForgottenFormComponent,
        PasswordResetComponent,
        PasswordResetFormComponent,
    ],
    // Export the GoogleButton in case we only want to use it without the login form
    exports: [LoginComponent, GoogleButtonComponent, PasswordForgottenComponent, PasswordResetComponent],
})
export class AuthModule {
    static forRoot(config: AuthModuleConfig): ModuleWithProviders<AuthModule> {
        let providers: Array<Provider | EnvironmentProviders> = [
            AuthService,
            AuthStore,
            PasswordResetService,
            {provide: AuthModuleToken, useValue: config},
            {
                provide: HTTP_INTERCEPTORS,
                useClass: CredentialsInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: RefreshTokenInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: LanguageInterceptor,
                multi: true,
            },
        ];

        if (config.enableGoogleAuth && config.googleClientId) {
            providers.push({
                provide: 'SocialAuthServiceConfig',
                useValue: {
                    autoLogin: false,
                    providers: [
                        {
                            id: GoogleLoginProvider.PROVIDER_ID,
                            provider: new GoogleLoginProvider(config.googleClientId),
                        },
                    ],
                } as SocialAuthServiceConfig,
            });
        }

        return {
            ngModule: AuthModule,
            providers,
        };
    }
}
