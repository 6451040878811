import {CommonModule} from '@angular/common';
import {MaterialModule} from '@ui/material';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

import {DrawerComponent} from './components/drawer/drawer.component';
import {DrawerHeaderComponent} from './components/drawer-header/drawer-header.component';

@NgModule({
    imports: [CommonModule, RouterModule.forChild([]), MaterialModule, TranslateModule.forChild()],
    declarations: [DrawerComponent, DrawerHeaderComponent],
    exports: [DrawerComponent, DrawerHeaderComponent],
})
export class DrawerModule {}
