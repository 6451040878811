import {ActivatedRouteSnapshot, Data, Router} from '@angular/router';
import {BehaviorSubject, tap} from 'rxjs';
import {excludeDrawerFromNavigationEvent, filterNavigationEndGuard, mapToRouteLastChild} from '@utils';
import {Injectable} from '@angular/core';

export type Breadcrumb = {
    label: string;
    url: string;
    display?: boolean;
    translate?: boolean;
}

@Injectable({providedIn: 'root'})
export class BreadcrumbService {

    // Subject emitting the breadcrumb hierarchy
    private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
    // Observable exposing the breadcrumb hierarchy
    readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

    /** @ignore */
    constructor(
        private router: Router,
        // eslint-disable-next-line no-empty-function
    ) {}

    /**
     * The initialize() method is used to initialise the service and listen to navigation events (NavigationEnd) via the router.
     * It uses filter and transformation operators to extract the current page title from the routing data (snapshot.data.title)
     * and uses it to set the breadcrumb.
     */
    initialize(): void {
        this.router.events.pipe(
            filterNavigationEndGuard(),
            excludeDrawerFromNavigationEvent(),
            mapToRouteLastChild(this.router),
            tap(() => {
                const root = this.router.routerState.snapshot.root;
                const breadcrumbs: Breadcrumb[] = [];
                this.addBreadcrumb(root, [], breadcrumbs);
                this._breadcrumbs$.next(breadcrumbs);
            })
        ).subscribe();
    }

    back(): void {
        const breadcrumbs = this._breadcrumbs$.getValue();
        if (breadcrumbs.length > 1) {
            this.router.navigate([breadcrumbs[breadcrumbs.length - 2].url]);
        }
    }

    private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]) {
        if (route) {
            // Construct the route URL
            const routeUrl = parentUrl.concat(route.url.map(url => url.path));

            // Add an element for the current route part
            if (route.data['breadcrumb']) {
                const breadcrumb = {
                    label: this.getLabel(route.data),
                    url: '/' + this.getUrl(route.data, routeUrl).join('/'),
                    display: (undefined !== route.data['breadcrumb']['display']) ? route.data['breadcrumb']['display'] : true,
                    translate: (undefined !== route.data['breadcrumb']['translate']) ? route.data['breadcrumb']['translate'] : true,
                };
                breadcrumbs.push(breadcrumb);
            }

            // Add another element for the next route part
            if (route.firstChild) {
                this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
            }
        }
    }

    private getLabel(data: Data): string {
        // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
        return 'function' === typeof data['breadcrumb']['label'] ? data['breadcrumb']['label'](data) : data['breadcrumb']['label'];
    }

    private getUrl(data: Data, routeUrl: string[]): string[] {
        return 'function' === typeof data['breadcrumb']['url'] ? data['breadcrumb']['url'](routeUrl) : routeUrl;
    }
}
