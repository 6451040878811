import {InjectionToken} from '@angular/core';

/**
 * Configuration object for libraries that need access to the API.
 */
export interface ApiConfig {
    /** The API base URL, without the trailing '/' */
    apiBaseUrl: string;
    ttl: number;
}

/** @ignore */
export const ApiToken = new InjectionToken<ApiConfig>(
    'ApiConfig'
);
