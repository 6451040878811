import {ElementRef, Injectable} from '@angular/core';
import {Observable, Subject, debounceTime} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScrollingService {
    /**
     * The scrolling() property is an observer that returns a stream of scrolling events.
     * @param Observable<number> scroll offset
     */
    public get scrolling(): Observable<number> {
        return this.onScroll.asObservable().pipe(debounceTime(5));
    }

    private onScroll: Subject<number> = new Subject<number>();

    /**
     * The contentScrollEvent() method is used to trigger a scroll event.
     * It takes as input a scroll offset (offsetY: number) and uses the next method to send this value to the event stream.
     * @param offsetY scroll offset
     */
    contentScrollEvent(offsetY: number): void {
        this.onScroll.next(offsetY);
    }

    /**
     * The isElementVisible() method returns a boolean indicating whether this element is visible on the screen.
     * by using the top and bottom properties of the element to check whether they are between 0 and the window height.
     * @param elementRef reference to an element
     */
    isElementVisible(elementRef: ElementRef): boolean {
        const rect = elementRef.nativeElement.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;

        return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    }

    scrollToElement(elementToScroll: HTMLElement, arg: boolean | ScrollIntoViewOptions = {block: 'end', inline: 'nearest', behavior: 'smooth'}): void {
        elementToScroll.scrollIntoView(arg);
    }

    /**
     * The focusAndScroll() method focuses the element and scrolls to the specified element using the focus() and scrollIntoView().
     * @param elementToFocus HTMLElement to focus
     * @param elementToScroll HTMLElement to scroll on
     * @param arg optional
     */
    focusAndScroll(elementToFocus: HTMLElement | null, elementToScroll: HTMLElement | null, arg: boolean | ScrollIntoViewOptions = {block: 'end', inline: 'nearest', behavior: 'smooth'}): void {
        if (elementToFocus) {
            elementToFocus.focus();
        }
        if (elementToScroll) {
            this.scrollToElement(elementToScroll, arg);
        }
    }
}
