<mat-card-content class="forgot">
    <form [formGroup]="form">
        <mat-form-field appearance="outline">
            <mat-label>{{ "COMMON.EMAIL" | translate }}</mat-label>
            <input formControlName="email" matInput required type="email" />
            <mat-error
                *ngIf="
                    form.controls['email'].dirty &&
                    form.controls['email'].hasError('required')
                "
            >
                {{ "ERROR.INPUT_NOT_COMPLETE" | translate }}
            </mat-error>
            <mat-error
                *ngIf="
                    form.controls['email'].dirty &&
                    form.controls['email'].hasError('email')
                "
            >
                {{ "ERROR.EMAIL_NOT_VALID" | translate }}
            </mat-error>
        </mat-form-field>
    </form>
</mat-card-content>
<mat-card-actions>
    <div>
        <button
            [disabled]="form.invalid || loading"
            (click)="submit()"
            class="col login-button"
            color="primary"
            data-cy="button-send-email"
            mat-raised-button
        >
            {{ "LOGIN.SEND_RESET" | translate }}
        </button>
    </div>
    <div>
        <a
            *ngIf="loginUrl"
            [routerLink]="loginUrl"
            color="primary"
            data-cy="to-login"
            mat-button
            >{{ "LOGIN.TO_LOG_IN" | translate }}</a
        >
    </div>
</mat-card-actions>
