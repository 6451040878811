import {Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef} from '@angular/core';

import {Permission, PermissionService} from '../services/permission.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[isGranted]',
})
export class IsGrantedDirective implements OnChanges {
    @Input() isGrantedTemplateNotGranted?: TemplateRef<any> = undefined;

    private displayTemplate = false;

    /** @ignore */
    constructor(
        private permissionService: PermissionService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        // eslint-disable-next-line no-empty-function
    ) {}

    /**
     * Fix: if the alternative template (isGrantedTemplateNotGranted) is provided before AfterViewInit, it won't be displayed.
     * ngOnChanges allows us to render it afterwards.
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isGrantedTemplateNotGranted']) {
            if (!this.displayTemplate && this.isGrantedTemplateNotGranted) {
                this.viewContainer.clear();
                this.viewContainer.createEmbeddedView(this.isGrantedTemplateNotGranted);
            }
        }
    }

    @Input() set isGranted(hasPermission: Permission) {
        if (this.permissionService.isGranted(hasPermission)) {
            this.displayTemplate = true;
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.displayTemplate = false;
            this.viewContainer.clear();
            if (this.isGrantedTemplateNotGranted) {
                this.viewContainer.createEmbeddedView(this.isGrantedTemplateNotGranted);
            }
        }
    }
}
