import {BehaviorSubject, Observable, Subject, catchError, combineLatest, iif, mergeMap, of, switchMap, tap} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {AxioError} from '@axiocode/error-handler';
import {SubSink} from 'subsink';

import {Application} from '../models/application.model';
import {ApplicationProvider} from './application.provider';
import {ApplicationStore} from './application.store';
import {Loading} from '../models/loading.interface';

type LoaderType = (application: Application) => Observable<unknown>;

@Injectable({providedIn: 'root'})
export class ApplicationLoaderService implements OnDestroy {
    /** @ignore */
    #subs = new SubSink();
    /** @ignore */
    #loaders: LoaderType[] = [];
    /** @ignore */
    #currentApplication$: BehaviorSubject<Application | undefined> = new BehaviorSubject<Application | undefined>(undefined);
    /** @ignore */
    #loadingApplication$: BehaviorSubject<Loading> = new BehaviorSubject<Loading>({loading: false, progression: 0});
    /** @ignore */
    #errors$: Subject<AxioError> = new Subject<AxioError>();

    /**
     * Returns the current cached application. You should avoid using this getter and use ApplicationStore
     * selectors instead.
     */
    get currentApplication$(): Observable<Application | undefined> {
        return this.#currentApplication$.asObservable();
    }

    /**
     * Returns the loading state of the application.
     */
    get isLoading$(): Observable<Loading> {
        return this.#loadingApplication$.asObservable();
    }

    /**
     * Emits loading errors.
     */
    get error$(): Observable<AxioError> {
        return this.#errors$.asObservable();
    }

    /** @ignore */
    constructor(private store: ApplicationStore, private provider: ApplicationProvider) {
        this.#subs.sink = this.store.selectSelectedEntity$.subscribe(app => this.#currentApplication$.next(app));
    }

    /** @ignore */
    ngOnDestroy(): void {
        this.#subs.unsubscribe();
    }

    loadApplication(id: number): Observable<Application | undefined> {
        const current = this.#currentApplication$.value;
        // Check if we already are on this application
        if (current?.id === id) {
            return of(current);
        }

        this.#loadingApplication$.next({loading: true, progression: 0});

        return this.provider.findOne$(id).pipe(
            tap(application => {
                // This observable pipe manages the dynamic loading of application dependencies and updates
                // the loading progression, without blocking the resolver since it provides the application
                // before the whole initialization process completes.
                this.#subs.sink = of(application).pipe(
                    tap(() => this.#loadingApplication$.next({...this.#loadingApplication$.value, progression: 30})),
                    tap(application => this.store.upsertOne(application)),
                    tap(application => this.store.setSelected(application)),
                    // If there are loaders, prepare and use them. Otherwise we skip.
                    mergeMap(application => iif(
                        () => this.#loaders.length > 0,
                        combineLatest(this.prepareLoaders(application)).pipe(
                            switchMap(() => of(application)),
                        ),
                        of(application)
                    )),
                    tap(() => this.#loadingApplication$.next({loading: false, progression: 100})),
                ).subscribe();
            }),
            catchError(error => {
                this.#loadingApplication$.next({...this.#loadingApplication$.value, loading: false});

                return of(error);
            }),
        );
    }

    registerLoader(loader: LoaderType): void {
        this.#loaders.push(loader);
    }

    private prepareLoaders(application: Application): Observable<unknown>[] {
        let observables: Observable<unknown>[] = [];
        for (const loader of this.#loaders) {
            observables.push(loader(application).pipe(
                tap(() => {
                    let progression = this.#loadingApplication$.value.progression;
                    progression += 70 / this.#loaders.length;
                    this.#loadingApplication$.next({...this.#loadingApplication$.value, progression});
                }),
                catchError(() => {
                    this.#errors$.next(new AxioError('ERROR.APPLICATION_LOADING_ERROR'));

                    return of([]);
                })
            ));
        }

        return observables;
    }
}