import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SocialUser} from '@abacritt/angularx-social-login';

import {AuthModuleConfig, AuthModuleToken} from '../token';
import {Identifiers} from '../models/identifiers.model';
import {Tokens} from '../models/auth.model';

@Injectable()
export class AuthService {
    // eslint-disable-next-line no-empty-function
    constructor(@Inject(AuthModuleToken) private readonly config: AuthModuleConfig, private http: HttpClient) {}

    /**
     * Attempts to authenticate the user with its identifiers.
     */
    public authenticate(identifiers: Identifiers): Observable<Tokens> {
        return this.http.post<Tokens>(`${this.config.authServiceBaseUrl}/api/login`, identifiers);
    }

    /**
     * Attempts to authenticate the user with its Google account.
     */
    public authenticateWithGoogle(identifiers: SocialUser): Observable<Tokens> {
        return this.http.post<Tokens>(`${this.config.authServiceBaseUrl}/sso/google?return=response`, {
            username: identifiers.email,
            idToken: identifiers.idToken,
        });
    }

    /**
     * Attempts to authenticate the user by using the refresh token.
     */
    public refreshToken(refreshToken: string): Observable<Tokens> {
        return this.http.post<Tokens>(`${this.config.apiBaseUrl}/refresh`, {refresh_token: refreshToken});
    }
}
