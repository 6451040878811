import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {Identifiers} from '../../../models/identifiers.model';

@Component({
    selector: 'auth-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent {
    /** If an error happened */
    @Input() errorMessage?: string = undefined;
    /** Set to true if the HTTP request is ongoing */
    @Input() loading = false;

    /** Emits the form when it is submitted */
    @Output() submitted: EventEmitter<Identifiers> = new EventEmitter();

    loginForm = new FormGroup<{
        username: FormControl<string>;
        password: FormControl<string>;
    }>({
        username: new FormControl<string>('', {
            nonNullable: true,
            validators: [Validators.required, Validators.email],
        }),
        password: new FormControl<string>('', {
            nonNullable: true,
            validators: [Validators.required],
        }),
    });
    hidePassword = true;

    public submit(): void {
        const credentials = this.loginForm.getRawValue();
        this.submitted.emit(credentials);
    }
}
