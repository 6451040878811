import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';

import {UserPreferencesTypes} from '../models/user-preferences-types';

/**
 * Structure of pagination preferences (for @axiocode/managed-table).
 */
export interface PaginationPreference {
    /** Items per page. */
    perPage: number;
}

/**
 * Structure of sorting preferences (for @axiocode/managed-table).
 */
export interface SortingPreference {
    /** The column on which to sort. */
    column: string;
    /** The sorting direction ('asc' or 'desc'). */
    dir: string;
}

/**
 * This type is the composition of all the types that can be stored as the value of a preference.
 */
export type PreferenceType = string | number | boolean | PaginationPreference | SortingPreference | null;

/**
 * Preferences are configurations that are specific to the user, such as the default items count in tables.
 * The preference service allows you to read and write preferences into local storage.
 * If you want to be informed by any change in the preferences, use the observable changes$.
 */
@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService {
    /** @ignore */
    private changes: Subject<UserPreferences> = new Subject<UserPreferences>();
    /**
     * This observable emits values every time the preferences change.
     */
    public get changes$(): Observable<UserPreferences> {
        return this.changes.asObservable();
    }

    /**
     * Saves a preference into local storage and notifies the subscribers of changes$.
     */
    public save(preference: UserPreferencesTypes, value: PreferenceType): void {
        let preferences = this.loadPreferences();
        preferences[preference] = value;

        this.savePreferences(preferences);
    }

    /**
     * Returns the value of a preference. If the preference is not found, returns null.
     */
    public get(preference: UserPreferencesTypes): PreferenceType {
        let preferences = this.loadPreferences();

        return preferences[preference] ?? null;
    }

    /**
     * Deletes the value of a preference.
     */
    public delete(preference: UserPreferencesTypes): void {
        let preferences = this.loadPreferences();
        delete preferences[preference];

        this.savePreferences(preferences);
    }

    /** @ignore */
    public isExpanded(type: UserPreferencesTypes): boolean {
        return this.get(type) as boolean;
    }

    /** @ignore */
    public getPagination(type: UserPreferencesTypes): number {
        return (this.get(type) as PaginationPreference)?.perPage ?? 20;
    }

    /** @ignore */
    public getSorting(type: UserPreferencesTypes): SortingPreference | null {
        return (this.get(type) as SortingPreference) ?? null;
    }

    /** @ignore */
    private loadPreferences(): UserPreferences {
        const userPreferencesStorage = localStorage.getItem('preferences') || '{}';

        return JSON.parse(userPreferencesStorage);
    }

    /** @ignore */
    private savePreferences(preferences: UserPreferences): void {
        window.localStorage.setItem('preferences', JSON.stringify(preferences));
        this.changes.next(preferences);
    }
}

/** @ignore */
interface UserPreferences {
    [key: string]: PreferenceType;
}

/**
 * Helper class to read and write @axiocode/managed-table preferences.
 */
export class ManagedTablePreferences {
    /** @ignore */
    public constructor(
        private userPreferencesService: UserPreferencesService,
        private paginationPreference: UserPreferencesTypes,
        private sortingPreference: UserPreferencesTypes
        // eslint-disable-next-line no-empty-function
    ) {}

    /** @ignore */
    public save(pagination: PaginationPreference, sorting: SortingPreference): void {
        this.userPreferencesService.save(this.paginationPreference, pagination);
        if (sorting.column) {
            this.userPreferencesService.save(this.sortingPreference, sorting);
        }
    }

    /** @ignore */
    public getItemsPerPage(): number {
        return this.userPreferencesService.getPagination(this.paginationPreference);
    }

    /** @ignore */
    public getSorting(): SortingPreference | null {
        return this.userPreferencesService.getSorting(this.sortingPreference);
    }
}
