import {Store, createAction, createReducer, on, props} from '@ngrx/store';
import {Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {isEqual} from 'lodash';

import {AuthState} from '../../models/auth-state.interface';

export const updateAuthState = createAction(
    '[Auth Store] Update Action',
    props<{componentName: string; state: AuthState}>()
);

export const authStateReducer = createReducer(
    {},
    on(updateAuthState, (previousState, {componentName, state}) => ({
        ...previousState,
        [componentName]: {...state}
    }))
);

export const linkToGlobalState = (authState$: Observable<AuthState>, componentName: string, globalStore: Store) => {
    authState$.pipe(
        distinctUntilChanged((prev, next) => isEqual(prev, next))
    ).subscribe(state => globalStore.dispatch(updateAuthState({componentName, state})));
};
