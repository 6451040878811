import {ApiConfig, ApiToken} from '@token';
import {EntityProvider, IdType} from '@axiocode/entity';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Application} from '../models/application.model';

@Injectable({providedIn: 'root'})
export class ApplicationProvider extends EntityProvider<Application> {
    override findAll$(): Observable<Application[]> {
        return this._findAll$(`${this.config.apiBaseUrl}/api/applications`);
    }

    override findOne$(id: IdType): Observable<Application> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/application/${id}`);
    }

    override create$(data: Partial<Application>): Observable<Application> {
        return this._create$(`${this.config.apiBaseUrl}/api/application`, data);
    }

    override update$(data: Partial<Application>, method: 'patch' | 'put'): Observable<Application> {
        return this._update$(`${this.config.apiBaseUrl}/api/application/${data.id}`, data, method);
    }

    override delete$(data: Application): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/application/${data.id}`);
    }

    constructor(@Inject(ApiToken) private config: ApiConfig) {
        super();

        this.ttl = this.config.ttl;
    }
}
