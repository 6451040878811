import {IdType} from '@axiocode/entity';
import {Injectable} from '@angular/core';
import {RouteBuilder} from '@axiocode/routing';

@Injectable({providedIn: 'any'})
export class GlossaryTermRoutes extends RouteBuilder {
    list = () => this.url('list');
    details = (id: IdType, editMode = false) => this.urlFromCommands([id], {
        ...editMode ? {queryParams: {edit: true}} : {}
    });
}
