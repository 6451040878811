<h2 mat-dialog-title *ngIf="configuration.title">
    {{ configuration.title | translate }}
</h2>

<mat-dialog-content class="modal" *ngIf="configuration.contentTemplate">
    <ng-container
        *ngTemplateOutlet="configuration.contentTemplate"
    ></ng-container>
</mat-dialog-content>

<mat-dialog-actions [attr.align]="'end'" *ngIf="configuration.actionsTemplate">
    <ng-container
        *ngTemplateOutlet="configuration.actionsTemplate"
    ></ng-container>
</mat-dialog-actions>
