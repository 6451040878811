import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpCacheInterceptorModule} from '@ngneat/cashew';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';

import {SnakeToCamelInterceptor} from './interceptors/snake-to-camel.interceptor';
import {entityStateReducer} from './services/debug/dev-tools.store';

@NgModule({
    imports: [
        HttpClientModule,
        HttpCacheInterceptorModule.forRoot(),
        StoreModule.forFeature({name: 'entityState', reducer: entityStateReducer})
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SnakeToCamelInterceptor,
            multi: true,
        },
    ]
})
export class AxiocodeEntityModule {}
