import {Component, Input} from '@angular/core';

@Component({
    selector: 'loader-application-loader',
    templateUrl: './application-loader.component.html',
    styleUrls: ['./application-loader.component.scss'],
})
export class ApplicationLoaderComponent {
    /** Progress value, percentage between 0 and 100 */
    @Input() value = 0;
}
