<form [formGroup]="form">
    <mat-card-content>
        <mat-form-field appearance="outline">
            <mat-label>{{ "LOGIN.NEW_PASSWORD" | translate }}</mat-label>
            <input
                [type]="hide ? 'password' : 'text'"
                formControlName="password"
                name="password"
                required
                matInput
                data-cy="new-password-input"
            />
            <button
                (click)="hide = !hide"
                [attr.aria-pressed]="hide"
                mat-icon-button
                matSuffix
                data-cy="hide-password-button"
            >
                <mat-icon>{{
                    hide ? "visibility_off" : "visibility"
                }}</mat-icon>
            </button>
            <mat-error
                *ngIf="
                    form.controls['password'].dirty &&
                    form.controls['password'].hasError('required')
                "
            >
                {{ "ERROR.INPUT_NOT_COMPLETE" | translate }}
            </mat-error>
            <mat-error
                *ngIf="
                    form.controls['password'].dirty &&
                    form.controls['password'].hasError('pattern')
                "
            >
                {{ "ERROR.PASSWORD_CHARACTERS" | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{ "LOGIN.CONFIRM_PASSWORD" | translate }}</mat-label>
            <input
                [type]="hide ? 'password' : 'text'"
                [errorStateMatcher]="parentErrorStateMatcher"
                formControlName="confirm"
                name="confirm-password"
                required
                matInput
                data-cy="password-confirmation-input"
            />
            <button
                (click)="hide = !hide"
                [attr.aria-pressed]="hide"
                mat-icon-button
                matSuffix
                data-cy="second-hide-password-button"
            >
                <mat-icon>{{
                    hide ? "visibility_off" : "visibility"
                }}</mat-icon>
            </button>
            <mat-error
                *ngIf="
                    form.controls['confirm'].dirty &&
                    form.controls['confirm'].hasError('required')
                "
            >
                {{ "ERROR.INPUT_NOT_COMPLETE" | translate }}
            </mat-error>
            <mat-error *ngIf="form.errors?.['differentPasswords']">
                {{ "ERROR.PASSWORD_DIFFERENT" | translate }}
            </mat-error>
        </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
        <p>
            <button
                [disabled]="form.invalid || loading"
                (click)="submit()"
                class="login-button"
                color="primary"
                mat-raised-button
                data-cy="reset-password-button"
            >
                {{ "LOGIN.RESET_PASSWORD" | translate }}
            </button>
        </p>
        <a [routerLink]="loginUrl" color="primary" mat-button>{{
            "LOGIN.TO_LOG_IN" | translate
        }}</a>
    </mat-card-actions>
</form>
