<mat-toolbar *ngIf="vm$ | async as vm">
    <mat-toolbar-row>
        <div class="application-switcher">
            <toolbar-organization-switcher
                *ngIf="vm.isLoggedIn && vm.currentUser"
                [currentOrganization]="vm.currentOrganization">
            </toolbar-organization-switcher>
            <toolbar-application-switcher
                *ngIf="vm.isLoggedIn && vm.currentUser"
                [currentApplication]="vm.currentApplication">
            </toolbar-application-switcher>
        </div>

        <div class="application-actions">
            <!-- SEARCH BAR -->
            <button *ngIf="vm.isLoggedIn && vm.currentApplication"
                (click)="openInputSearch()"
                [hotkey]="hotkeys.SEARCH"
                hotkeyGroup="KEYMAP.GROUP.GENERAL"
                hotkeyDescription="KEYMAP.SEARCH_OPEN"
                mat-icon-button
                data-cy="toolbar-search-trigger">
                <mat-icon>search</mat-icon>
            </button>
            <ng-container *ngIf="vm.currentApplication">
                <toolbar-global-search
                    [hidden]="!vm.isSearchOpened"
                    [application]="vm.currentApplication">
                </toolbar-global-search>
            </ng-container>
            <!-- QUICK MENU -->
            <button *ngIf="vm.isLoggedIn"
                [matMenuTriggerFor]="contextualMenu"
                mat-icon-button
                data-cy="toolbar-menu-trigger">
                <mat-icon>apps</mat-icon>
            </button>
            <mat-menu #contextualMenu="matMenu" class="contextual-menu">
                <a mat-menu-item (click)="goTo({destination: 'application_selection'})">
                    <mat-icon>apps</mat-icon>
                    {{ 'APPLICATION.APPLICATION_SELECTION' | translate }}
                </a>
                <a mat-menu-item (click)="goTo({destination: 'organizations'})">
                    <mat-icon>groups</mat-icon>
                    {{ 'ORGANIZATION.ORGANIZATION_LIST' | translate }}
                </a>
                <div *ngIf="vm.currentApplication">
                    <a mat-menu-item
                        (click)="goTo({destination: 'application_details', application: vm.currentApplication})">
                        <mat-icon>visibility</mat-icon>
                        {{ 'APPLICATION.APPLICATION_DETAIL' | translate }}
                    </a>
                </div>
                <mat-divider></mat-divider>
                <div *ngIf="vm.currentApplication">
                    <div mat-subheader>{{ 'TOOLBAR.SECTION_ACTION' | translate }}</div>
                    <ng-container *ngFor="let item of vm.quickMenuItems">
                        <a mat-menu-item
                            *isGranted="{target: vm.currentApplication, permission: 'write', type: 'application'}"
                            (click)="openDialogAdd(item, vm.currentApplication)"
                            [hotkey]="item.dialogHotkeys"
                            [hotkeyDescription]="item.label"
                            hotkeyGroup="KEYMAP.GROUP.DIALOG"
                            [hotkeySequence]="item.isSequence">
                            <mat-icon>add</mat-icon>
                            {{ item.label | translate }}
                        </a>
                    </ng-container>
                </div>
            </mat-menu>
            @if (vm.currentApplication) {
            <div *isGranted="{target: vm.currentApplication, permission: 'comment', type: 'application'}">
                <button mat-icon-button *ngIf="vm.allOpenedDiscussions !== 0"
                    (click)="goTo({destination: 'application_discussion', application: vm.currentApplication})">
                    <mat-icon
                        [matBadge]="vm.allOpenedDiscussions"
                        matBadgePosition="above"
                        matBadgeColor="warn"
                        matBadgeSize="small"
                        data-cy="toolbar-menu-trigger"
                        aria-hidden="false">
                        forum
                    </mat-icon>
                </button>
                <button mat-icon-button *ngIf="vm.allOpenedDiscussions === 0"
                    (click)="goTo({destination: 'application_discussion', application: vm.currentApplication})">
                    <mat-icon
                        data-cy="toolbar-menu-trigger">
                        forum
                    </mat-icon>
                </button>
            </div>
            }
            <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>account_circle</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="user-menu">
                <button
                    *ngIf="vm.isLoggedIn && vm.currentUser"
                    (click)="goTo({destination: 'user_details', userId: vm.currentUser.id})"
                    mat-menu-item>
                    {{ vm.currentUser | displayName }}
                </button>
                <div *ngIf="vm.currentApplication">
                    <a mat-menu-item
                        (click)="openHotkeys()">
                        <mat-icon>question_mark</mat-icon>
                        {{ 'TOOLBAR.SHORTCUTS' | translate }}
                    </a>
                </div>
                <toolbar-lang-switcher></toolbar-lang-switcher>
                <button mat-menu-item *ngIf="vm.isLoggedIn" class="logout" (click)="logout()">
                    {{ 'LOGIN.LOGOUT' | translate }}
                </button>
            </mat-menu>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
