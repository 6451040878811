import {ApiConfig, ApiToken} from '@token';
import {Application, ApplicationStore} from '@application/data';
import {EntityProvider, IdType} from '@axiocode/entity';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription, distinctUntilChanged, map, tap} from 'rxjs';

import {GlossaryTerm} from '../models/glossary-term.model';

@Injectable({providedIn: 'root'})
export class GlossaryTermProvider extends EntityProvider<GlossaryTerm> implements OnDestroy {
    /** @ignore */
    private application: Application | undefined = undefined;
    /** @ignore */
    private subscription: Subscription;

    override findAll$(): Observable<GlossaryTerm[]> {
        if (undefined === this.application) {
            throw new Error('Current application is not defined.');
        }

        return this._findAll$(`${this.config.apiBaseUrl}/api/application/${this.application.id}/glossaryterms`).pipe(
            map(terms => terms.map(term => ({...term, name: term.word})))
        );
    }

    override findOne$(id: IdType): Observable<GlossaryTerm> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/glossaryterm/${id}`).pipe(
            map(term => ({...term, name: term.word}))
        );
    }

    override create$(data: Partial<GlossaryTerm>): Observable<GlossaryTerm> {
        return this._create$(`${this.config.apiBaseUrl}/api/glossaryterm`, data).pipe(
            map(term => ({...term, name: term.word}))
        );
    }

    override update$(data: Partial<GlossaryTerm>, method: 'patch' | 'put'): Observable<GlossaryTerm> {
        return this._update$(`${this.config.apiBaseUrl}/api/glossaryterm/${data.id}`, data, method).pipe(
            map(term => ({...term, name: term.word}))
        );
    }

    override delete$(data: GlossaryTerm): Observable<void> {
        return this._delete$(`${this.config.apiBaseUrl}/api/glossaryterm/${data.id}`);
    }

    constructor(
        @Inject(ApiToken) private config: ApiConfig,
        applicationStore: ApplicationStore,
    ) {
        super();

        this.ttl = this.config.ttl;

        this.subscription = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the cache as we changed the current application
            tap(() => this.resetCache()),
            tap(app => this.application = app),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
