<div class="search-space" (mouseenter)="searchMouseEventIn()" (mouseleave)="searchMouseEventOut()">
    <div>
        <div class="searchbar">
            <mat-form-field #searchFieldHtml disabled="true">
                <!-- <mat-label>{{ 'SEARCH.SEARCH' | translate }}</mat-label>
                <mat-icon matPrefix>search</mat-icon> -->
                <input
                    matInput type="text"
                    id="searchInput"
                    autocomplete="off"
                    [formControl]="searchField"
                    (keydown)="onKeyDown($event)"
                    (focus)="focusInput()"
                    (focusout)="focusInput()"
                    >
                <button *ngIf="searchField" matSuffix mat-icon-button aria-label="Clear" (click)="clearInputField()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
    <div class="search-container" [hidden]="!focusResult && !mouseEnterResult">
        <div class="overview-content">
            <div
                *ngFor="let result of results$ | async; let index = index; trackBy: trackResult"
                [class]="{'overview-item': true, 'selected': index === selectedIndex}"
                [id]="'search-result-' + index">
                <div (click)="openPreview(result.typeClass?.toString()?.toLowerCase(), result.id)">
                    <div class="overview-title">
                        <div class="search-name">
                            <span class="code-prefix-search" *ngIf="result.codeAndPrefix"
                                [innerHTML]="result.codeAndPrefix | highlight: query"></span>
                            <h3 [innerHTML]="result.name | highlight: query"></h3>
                        </div>
                        <span class="type">{{ "TYPE_CLASS."+result.typeClass | uppercase | translate }} </span>
                    </div>
                    <div class="description">
                        <ng-template #description>
                            <div [innerHTML]="result.description | noHtml | highlight: query"></div>
                        </ng-template>
                        <div
                            *ngIf="(result.matchContents !== result.name && result.matchContents !== result.codeAndPrefix); else description"
                            [innerHTML]="result.matchContents | noHtml | highlight: query"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
