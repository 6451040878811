import {Application, ApplicationStore, QuickMenuItem, QuickMenuLoaderService} from '@application/data';
import {Component, OnDestroy} from '@angular/core';
import {DialogComponent, DialogHandle, DialogService} from '@ui/dialog';
import {combineLatest, map, take, tap} from 'rxjs';
import {AppRoutes} from '@routing';
import {AuthStore} from '@user/auth';
import {ComponentType} from '@angular/cdk/portal';
import {DiscussionStore} from '@discussion/data';
import {DrawerService} from '@ui/drawer';
import {Hotkeys} from '@configuration';
import {HotkeysDialogComponent} from '@ui/hotkeys';
import {IdType} from '@axiocode/entity';
import {OrganizationStore} from '@organization/data';
import {SubSink} from 'subsink';
import {UserStore} from '@user/data';
import {Versionable} from '@versionable/data';
import {exhaustiveMatchGuard} from '@utils';

import {ToolbarLocalStore} from '../../+state/toolbar-local.store';

@Component({
    selector: 'toolbar-application',
    templateUrl: './application-toolbar.component.html',
    styleUrls: ['./application-toolbar.component.scss'],
    providers: [ToolbarLocalStore]
})
export class ApplicationToolbarComponent implements OnDestroy {
    quickMenuItems$ = this.quickMenuService.items$.pipe(
        map(items => items.sort((a, b) => a.label < b.label ? -1 : 1))
    );
    vm$ = combineLatest([
        this.applicationStore.selectSelectedEntity$,
        this.organizationStore.selectSelectedEntity$,
        this.store.selectIsLoggedIn$,
        this.userStore.selectCurrentUser$,
        this.quickMenuItems$,
        this.store.selectIsSearchOpened$,
        this.discussionStore.selectAllOpenedDiscussions$
    ]).pipe(
        map(([currentApplication, currentOrganization, isLoggedIn, currentUser, quickMenuItems, isSearchOpened, allOpenedDiscussions]) => ({currentApplication, currentOrganization, isLoggedIn, currentUser, quickMenuItems, isSearchOpened, allOpenedDiscussions: allOpenedDiscussions.length})),
    );

    hotkeys = Hotkeys;

    #dialog?: DialogHandle = undefined;
    #subs = new SubSink();

    constructor(
        private authStore: AuthStore,
        private userStore: UserStore,
        private applicationStore: ApplicationStore,
        private organizationStore: OrganizationStore,
        private store: ToolbarLocalStore,
        private discussionStore: DiscussionStore,
        private quickMenuService: QuickMenuLoaderService,
        private dialogService: DialogService,
        private drawerService: DrawerService,
        public routes: AppRoutes,
        // eslint-disable-next-line no-empty-function
    ) {}

    ngOnDestroy(): void {
        this.#subs.unsubscribe();
    }

    openInputSearch(): void {
        this.store.setIsSearchOpened(true);
        setTimeout(() => {
            document.getElementById('searchInput')?.focus();
        }, 0);
    }

    openDialogAdd(item: QuickMenuItem, application: Application) {
        this.closeDialog();
        this.#dialog = this.dialogService.open(item.dialogComponent as ComponentType<DialogComponent>, {
            title: item.dialogTitle,
            data: {
                application: application,
                displaySaveAndRedirect: true,
            }
        });
        this.#subs.sink = this.#dialog?.componentInstance.dialogAccepted.subscribe(() => {
            this.closeDialog();
        });
        this.#subs.sink = this.#dialog?.componentInstance.dialogAcceptedRedirect.subscribe(value => {
            this.closeDialog();
            item.detailsUrl(application.id, (value as Versionable).id).navigate();
        });
        this.#subs.sink = this.#dialog?.componentInstance.dialogAcceptedAndAdd.subscribe(() => {
            // Re-open the dialog only when the application has been updated with the newest codes
            this.applicationStore.selectSelectedEntity$.pipe(
                take(1),
                tap(app => this.openDialogAdd(item, app ?? application))
            ).subscribe();
        });
    }

    openHotkeys(): void {
        this.#dialog = this.dialogService.open(HotkeysDialogComponent);
    }

    closeDialog(): void {
        this.#dialog?.close();
    }

    goTo(destination: MenuLinks): void {
        switch (destination.destination) {
            case 'application_selection':
                this.drawerService.closeDrawer().then(() => this.routes.application.selection().navigate());
                break;

            case 'application_details':
                this.drawerService.closeDrawer().then(() => this.routes.application.details(destination.application.id).navigate());
                break;

            case 'organizations':
                this.drawerService.closeDrawer().then(() => this.routes.organization.list().navigate());
                break;

            case 'user_details':
                this.drawerService.closeDrawer().then(() => this.routes.user.details(destination.userId).navigate());
                break;

            case 'application_discussion':
                this.drawerService.closeDrawer().then(() => this.routes.application.in(destination.application.id).discussion.list().navigate());
                break;

            default: exhaustiveMatchGuard(destination);
        }
    }

    logout(): void {
        this.authStore.logout();
    }

}

type MenuLinks =
    | {
        destination: 'application_selection',
    }
    | {
        destination: 'application_details',
        application: Application,
    }
    | {
        destination: 'organizations',
    }
    | {
        destination: 'user_details',
        userId: IdType,
    }
    | {
        destination: 'application_discussion',
        application: Application,
    };
