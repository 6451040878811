import {Component, Input} from '@angular/core';

import {ActionButtonComponent} from './action-button.component';

@Component({
    selector: 'ui-add-button',
    templateUrl: './action-button.component.html'
})
export class AddButtonComponent extends ActionButtonComponent {

    @Input() override color = 'primary';
    @Input() override icon = 'add';

}
