import {ApiConfig, ApiToken} from '@token';
import {EntityProvider, IdType} from '@axiocode/entity';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Organization} from '../models/organization.model';

@Injectable({providedIn: 'root'})
export class OrganizationProvider extends EntityProvider<Organization> {
    override findAll$(): Observable<Organization[]> {
        return this._findAll$(`${this.config.apiBaseUrl}/api/organizations`);
    }

    override findOne$(id: IdType): Observable<Organization> {
        return this._findOne$(`${this.config.apiBaseUrl}/api/organization/${id}`);
    }

    override create$(organization: Organization): Observable<Organization> {
        let formData = this.formDataOrganization(organization);

        return this._create$(`${this.config.apiBaseUrl}/api/organization`, formData as Partial<Organization>);
    }

    override update$(organization: Organization): Observable<Organization> {
        let formData = this.formDataOrganization(organization);

        return this.http.post<Organization>(
            `${this.config.apiBaseUrl}/api/organization/${organization.id}`,
            formData as Partial<Organization>
        );
    }

    override delete$(data: Organization): Observable<void> {
        throw new Error('Method not implemented.');
    }

    constructor(@Inject(ApiToken) private config: ApiConfig) {
        super();

        this.ttl = this.config.ttl;
    }

    public formDataOrganization(organization: Partial<Organization>): FormData {
        const formData = new FormData();

        if (organization.importLogo) {
            formData.append('importLogo', organization.importLogo, organization.importLogo.name);
        }

        if (organization.logo) {
            formData.append('logo', organization.logo as string);
        }
        formData.append('name', organization.name as string);

        // Referent
        formData.append('lastname', organization.referent?.lastname as string);
        formData.append('firstname', organization.referent?.firstname as string);
        formData.append('email', organization.referent?.email as string);
        formData.append('phone', organization.referent?.phone as string);

        return formData;
    }
}
