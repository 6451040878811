import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {OrganizationStore} from '@organization/data';
import {inject} from '@angular/core';
import {tap} from 'rxjs';

import {Application} from '../models/application.model';
import {ApplicationLoaderService} from '../services/application-loader.service';

/**
 * This resolver fetches the application from the path parameter "applicationId" and sets the application as the current one.
 */
export const applicationResolver: ResolveFn<Application | undefined | null> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const loader = inject(ApplicationLoaderService);
    const id = route.paramMap.get('applicationId');
    const organizationStore = inject(OrganizationStore);

    if (null === id) {
        return null;
    }

    organizationStore.findAll();

    return loader.loadApplication(Number(id)).pipe(
        tap(application => {
            if (application?.organization) {
                organizationStore.upsertOne(application.organization);
            }
        }),
        tap(application => organizationStore.setSelected(application?.organization)),
    );
};
