import {removeAccents, removeRegexSymbols} from '@utils';
import {SearchResult} from '../models/search-result.model';

/**
 * This represents a field on which to match the query as well as a weight if matches are found.
 * The field can be a property accessor in which each property is separated by a ".", ie: features.name.
 */
export interface SearchParameter {
    field: string;
    weight: number;
}

export interface SearchMatch {
    searchParameter: SearchParameter;
    weight: number;
    contents: string;
}

/**
 * Extract the value of the field "propertyName" from object "o". Used for safe typings.
 */
function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
}

/**
 * This class allows the search on various fields of various objects with weights.
 */
export class WeightedSearchService<T> {

    /**
     * Starts a search on a collection of objects "objectsToSearch" for the string "query" on the fields
     * "searchParameters". The weights used in "searchParameters" will be used to compute the global weight of each
     * object such as: totalWeight = Ε(matches x weight)
     */
    public search(objectsToSearch: T[], query: string, searchParameters: SearchParameter[]): SearchResult[] {
        let results = this.generateWeightedObjects(objectsToSearch, query, searchParameters);
        results.sort((a, b) => a.weight >= b.weight ? -1 : 1);

        return results;
    }

    /**
     * Look for each parameter in each objects in order to compute the sum of the weights.
     */
    private generateWeightedObjects(
        objectsToSearch: any[],
        query: string,
        searchParameters: SearchParameter[]
    ): SearchResult[] {
        let results: SearchResult[] = [];

        for (let object of objectsToSearch) {
            let matches: SearchMatch[] = [];

            // Recursive search
            for (let parameter of searchParameters) {
                const match = this.navigate(object, query, parameter);
                matches = [...matches, ...match];
            }

            matches.sort((a, b) => a.weight >= b.weight ? -1 : 1);
            const totalWeight = matches.reduce((total, match) => total += match.weight, 0);

            if (totalWeight > 0) {
                let result = {
                    weight: totalWeight,
                    sourceObject: object,
                    matchContents: matches[0].contents as string,
                    name: object.name,
                    description: object.description,
                    id: object.id,
                    typeClass: object.typeClass,
                    codeAndPrefix: object.codeAndPrefix,
                    status: object.versionStatusValue
                };

                results.push(result);
            }
        }

        return results;
    }

    /**
     * Extract the property value given the "parameters.field" and taking dot separated property paths into account.
     * Once the value is found, starts to search on it and returns the relative weight for the current parameter.
     */
    private navigate(object: any, query: string, parameter: SearchParameter): SearchMatch[] {
        if (undefined === object || null === object) {
            return [];
        }

        let field = parameter.field;
        let matches: SearchMatch[] = [];
        const dotIndex = field.indexOf('.');
        if (0 < dotIndex) {
            let part = field.substring(0, dotIndex);
            const clone = {...parameter};
            clone.field = clone.field.substring(dotIndex + 1);
            object = getProperty(object, part as never);

            if (Array.isArray(object)) {
                for (const item of object) {
                    const match = this.navigate(item, query, clone);
                    if (match.length) {
                        matches = [...matches, ...match];
                    }
                }

                return matches;
            }

            return this.navigate(object, query, clone);
        }

        if (undefined === getProperty(object, parameter.field as never)) {
            return [];
        }


        const match = this.doSearch(object, query, parameter);

        return [match];
    }

    private doSearch(objectToSearch: any, query: string, parameter: SearchParameter): SearchMatch {
        let weight = 0;
        let match = {searchParameter: parameter, weight: 0, contents: ''};

        if (Array.isArray(objectToSearch)) {
            for (let current of objectToSearch) {
                const data = getProperty(current, parameter.field as never);
                weight += this.keywordMatches(data, query) * parameter.weight;
                match.contents = data;
            }

            match.weight = weight;

            return match;
        }

        if ('object' === typeof objectToSearch) {
            const data = getProperty(objectToSearch, parameter.field as never);
            weight = this.keywordMatches(data, query) * parameter.weight;
            match.weight = weight;
            match.contents = data;

            return match;
        }

        weight = this.keywordMatches(objectToSearch, query) * parameter.weight;
        match.weight = weight;
        match.contents = objectToSearch;

        return match;
    }

    /**
     * Checks how many times query matches data and returns the number of matches.
     */
    private keywordMatches(data: string, query: string): number {
        if (0 === data.length || 0 === query.length) {
            return 0;
        }

        data = removeAccents(data);
        query = removeAccents(query);
        query = removeRegexSymbols(query);

        try {
            const regexp = new RegExp(query, 'igu');
            data = data.replace(/<[^>]*>/g, '');

            return (data.match(regexp) || []).length;
        } catch (e) {
            // The user has entered invalid characters or sequence of characters.
        }

        return 0;
    }
}
