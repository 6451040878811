import {Injectable, Injector, Type, inject} from '@angular/core';
import {NavigationBehaviorOptions, Router, UrlCreationOptions, UrlTree} from '@angular/router';

/**
 * Provides easy access to either the URL as string, the UrlTree or a `.navigate()` method.
 */
export class Url {
    /** Resolved absolute URL */
    url: string;

    constructor(public urlTree: UrlTree, private router: Router) {
        this.url = this.urlTree.toString();
    }

    /**
     * Navigate to this URL
     */
    navigate(navigationExtras?: NavigationBehaviorOptions) {
        this.router.navigateByUrl(this.url, navigationExtras);
    }
}

/**
 * Utility abstract class that provides methods to declare
 * application routes.
 *
 * Declare your application routes
 * ```
 * \@Injectable({ providedIn: 'any' })
 * export class AppRoutes extends RouteBuilder {
 *   path = '';
 *
 *   feature1 = this.childRoutes('feature1', RoutesForFeature1);
 *
 *   admin() {
 *     return this.url('admin');
 *   }
 *
 *   orders(id?: string) {
 *     return this.urlFromCommands(['orders', id]);
 *   }
 * }
 * ```
 * Declare routes for your lazy feature module:
 * ```
 * \@Injectable({ providedIn: 'any' })
 * export class RoutesForFeature1 extends RouteBuilder {
 *   todo() {
 *     return this.url('todo');
 *   }
 * }
 * ```
 * Use the AppRotes in your components/services
 * ```
 * export class MyComponent {
 *   constructor(private appRoutes: AppRoutes) {}
 *
 *   navigateToFeature1() {
 *     this.appRoutes.feature1.todo().navigate();
 *   }
 * }
 * ```
 */
@Injectable()
export class RouteBuilder {
    /** Router instance */
    protected router = inject(Router);

    /** Injector instance */
    private injector = inject(Injector);

    /** Reference to the parent if we have one */
    private parent?: RouteBuilder = undefined;

    /** Relative root path associated to the module defining the RouteBuilder */
    private path = '';

    private get parentCommands() {
        let parent = this.parent;
        const commands = [this.path];
        while (parent) {
            commands.unshift(parent.path);
            parent = parent.parent;
        }

        return commands;
    }

    /**
     * The root AppUrl of the route associated with the lazy feature module
     */
    root(): Url {
        return this.urlFromCommands([]);
    }

    /**
     * Declare a route using relative URL of that route
     * ```
     * export class AppRoutes extends RouteBuilder {
     *   admin() {
     *     return this.url('admin');
     *   }
     * }
     * ```
     */
    protected url(url: string): Url {
        return this.urlFromCommands([url]);
    }

    /**
     * @param commands An array of URL fragments with which to construct the new URL tree.
     * If the path is static, can be the literal URL string. For a dynamic path, pass an
     * array of path segments, followed by the parameters for each segment. The fragments
     * are applied to the current URL tree or the one provided in the relativeTo property
     * of the options object, if supplied.
     * @param navigationExtras Options that control the navigation strategy.
     *
     * ```
     * export class AppRoutes extends RouteBuilder {
     *   orders(id?: string) {
     *     return this.urlFromCommands(['orders', id]);
     *   }
     * }
     * ```
     */
    protected urlFromCommands(commands: (string | number | undefined)[], navigationExtras?: UrlCreationOptions): Url {
        const sanitizedCommands = commands.filter(c => !!c);
        const urlTree = this.router.createUrlTree(
            [...this.parentCommands, ...sanitizedCommands],
            navigationExtras
        );

        return new Url(urlTree, this.router);
    }

    /**
     * Defines either child routes or routes associated with lazy feature module
     * ```
     * export class AppRoutes extends RouteBuilder {
     *   feature1 = this.childRoutes('feature', RoutesForFeature1);
     * }
     * ```
     */
    protected childRoutes<T extends RouteBuilder>(
        path: string,
        childRouteBuilderClass: Type<T>
    ) {
        const pathBuilder = this.injector.get<T>(childRouteBuilderClass);
        pathBuilder.parent = this;
        pathBuilder.path = path;

        return pathBuilder;
    }
}
